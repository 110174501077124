import moment from "moment";

export const selectOptions = ({ type }) => {
  if (type === "0") {
    return [
      { value: 0, label: "ID" },
      { value: 1, label: "이름" },
      { value: 2, label: "닉네임" },
      { value: 3, label: "휴대폰" },
      { value: 4, label: "이메일" },
      { value: 5, label: "추천인" },
    ];
  }
};

// 정렬
export const customSorting = ({ sort, data }) => {
  let convertedData = data;
  for (let i = 0; i < sort.length; i++) {
    let selectorArr = sort[i].selector.split(".");
    //selector desc
    if (sort[i].desc) {
      convertedData.sort((a, b) => {
        let prev = a,
          next = b;

        for (let j = 0; j < selectorArr.length; j++) {
          prev = prev[selectorArr[j]];
          next = next[selectorArr[j]];
        }
        return prev < next ? -1 : prev > next ? 1 : 0;
      });
    } else {
      convertedData.sort((a, b) => {
        let prev = a,
          next = b;
        for (let j = 0; j < selectorArr.length; j++) {
          prev = prev[selectorArr[j]];
          next = next[selectorArr[j]];
        }
        return prev > next ? -1 : prev < next ? 1 : 0;
      });
    }
  }
};

// 정렬 string

export const convertSortToString = (sort) => {
  const result = {
    orderBy: [],
  };
  if (sort !== null) {
    for (let i = 0; i < sort.length; i += 1) {
      const orderByObj = {};
      orderByObj[`${sort[i].selector}`] = sort[i].desc ? "desc" : "asc";

      result.orderBy.push(orderByObj);
    }
  }

  return JSON.stringify(result);
};

export const dateCalculate = (value) =>
  value === null ? null : new Date(parseInt(value));

export const dateValueToString = (value, type) => {
  const dateData = moment(new Date(parseInt(value)));
  if (type === 0) return dateData.format("YYYY-MM-DD");
  return dateData.format("YYYY-MM-DD HH:mm:ss");
};

export const initialConditionState = ({ date, time, text }) => {
  const inquiryStart = new Date(
    moment().set({ hours: 0, minutes: 0, seconds: 0 })
  );
  const inquiryEnd = new Date(
    moment().set({ hours: 23, minutes: 59, seconds: 59 })
  );

  const result = {};
  if (date) {
    result.inquiryStart = inquiryStart;
    result.inquiryEnd = inquiryEnd;
  }
  if (text) {
    result.inquiryType = 0;
    result.inquiryValue = "";
  }

  return result;
};

export const invertDateObj = ({ inquiryStart, inquiryEnd }) => {
  return {
    inquiryStart: `${inquiryStart.getFullYear()}-${
      inquiryStart.getMonth() + 1 < 10
        ? `0${inquiryStart.getMonth() + 1}`
        : inquiryStart.getMonth() + 1
    }-${
      inquiryStart.getDate() < 10
        ? `0${inquiryStart.getDate()}`
        : inquiryStart.getDate()
    }`,
    inquiryEnd: `${inquiryEnd.getFullYear()}-${
      inquiryEnd.getMonth() + 1 < 10
        ? `0${inquiryEnd.getMonth() + 1}`
        : inquiryEnd.getMonth() + 1
    }-${
      inquiryEnd.getDate() < 10
        ? `0${inquiryEnd.getDate()}`
        : inquiryEnd.getDate()
    }`,
  };
};

export const bankCategory = [
  {
    label: "은행권",
    options: [
      { value: 0, label: "국민은행" },
      { value: 1, label: "기업은행" },
      { value: 2, label: "농협은행" },
      { value: 3, label: "신한은행" },
      { value: 4, label: "우체국은행" },
      { value: 5, label: "SC은행" },
      { value: 6, label: "하나은행" },
      { value: 7, label: "한국씨티은행" },
      { value: 8, label: "우리은행" },
      { value: 9, label: "경남은행" },
      { value: 10, label: "광주은행" },
      { value: 11, label: "대구은행" },
      { value: 12, label: "도이치은행" },
      { value: 13, label: "부산은행" },
      { value: 14, label: "산업은행" },
      { value: 15, label: "수협중앙회" },
      { value: 16, label: "전북은행" },
      { value: 17, label: "제주은행" },
      { value: 18, label: "새마을금고연합회" },
      { value: 19, label: "신용협동조합" },
      { value: 20, label: "HSBC은행" },
      { value: 21, label: "저축은행" },
      { value: 22, label: "BOA은행" },
      { value: 23, label: "케이뱅크" },
      { value: 24, label: "카카오뱅크" },
      { value: 25, label: "JP모간체이스은행" },
      { value: 26, label: "BNP파리바은행" },
      { value: 27, label: "중국건설은행" },
      { value: 28, label: "산립조합" },
      { value: 29, label: "중국공상은행" },
      { value: 30, label: "지역농축업" },
    ],
  },
  {
    label: "증권사",
    options: [
      { value: 31, label: "NH투자증권" },
      { value: 32, label: "유안타증권" },
      { value: 33, label: "KB증권" },
      { value: 34, label: "미래에셋대우" },
      { value: 35, label: "삼성증권" },
      { value: 36, label: "한국투자증권" },
      { value: 37, label: "교보증권" },
      { value: 38, label: "하이투자증권" },
      { value: 39, label: "현대차증권" },
      { value: 40, label: "SK증권" },
      { value: 41, label: "한화투자증권" },
      { value: 42, label: "하나금융투자" },
      { value: 43, label: "신한금융투자" },
      { value: 44, label: "유진투자증권" },
      { value: 45, label: "메리츠종합금융증권" },
      { value: 46, label: "신영증권" },
      { value: 47, label: "이베스트투자증권" },
      { value: 48, label: "케이프투자증권" },
      { value: 49, label: "부국증권" },
      { value: 50, label: "키움증권" },
      { value: 51, label: "대신증권" },
      { value: 52, label: "DB금융투자" },
      { value: 53, label: "KTB투자증권" },
      { value: 54, label: "펀드온라인코리아" },
    ],
  },
];

export const getBankName = (value) => {
  for (const category of bankCategory) {
    for (const option of category.options) {
      if (option.value === value) {
        return option.label;
      }
    }
  }
  return "";
};

// will be deprecated
export const memberGradeDatas = [
  { value: 0, label: "준회원" },
  { value: 1, label: "정회원" },
  { value: 2, label: "추천인" },
  { value: 3, label: "관리자" },
  { value: 4, label: "모의투자" },
  { value: 5, label: "이벤트" },
];

// will be deprecated
export const memberGradeArr = [
  "준회원",
  "정회원",
  "추천인",
  "관리자",
  "모의투자",
  "이벤트",
];

// query String
export const getQueryString = () => {
  const href = window.location.href;
  const queryStr = href.substring(href.lastIndexOf("?") + 1);
  if (queryStr.indexOf("id") !== -1) {
    return queryStr.replace("id=", "");
  }
  return "";
};
