import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { Space, Button, Input, Segmented } from "antd";

import {
  CancelButton,
  ConcludeButton,
  IsBuyCellRender,
  ValueCell,
} from "../../../common/CustomCell";
import { ProCard } from "@ant-design/pro-components";
import { useMutation } from "@apollo/client";
import { CANCEL_ORDER, CONCLUDE_ORDER } from "../../../graphql/manageOrder";
import { useUserState } from "../../../../context";
import { ReloadOutlined } from "@ant-design/icons";
import { CustomBasicTable } from "../../../common/CustomTable";

const { Search } = Input;
const Container = styled.div`
  h3 {
    font-weight: bold;
  }
  th {
    text-align: center;
  }
  .actionButton {
    padding: 0 !important;
    &:hover {
      cursor: pointer;
    }
  }
`;

const getOrderStatusProfitStatusColumn = (filteredInfo, sortedInfo) => [
  {
    title: "아이디",
    dataIndex: "id",
    valueType: "text",
    fixed: "left",
    filteredValue: filteredInfo.id || null,
    onFilter: (value, record) => record.id.includes(value),
    sorter: (a, b) => a.id > b.id,
    sortOrder: sortedInfo.field === "id" ? sortedInfo.order : null,
  },
  {
    title: "이름",
    dataIndex: "userName",
    valueType: "text",
    width: 80,
    filteredValue: filteredInfo.userName || null,
    onFilter: (value, record) => record.userName.includes(value),
    sorter: (a, b) => a.userName > b.userName,
    sortOrder: sortedInfo.field === "userName" ? sortedInfo.order : null,
  },
  {
    title: "추천인",
    dataIndex: "recommendCode",
    valueType: "text",
    filteredValue: filteredInfo.recommendCode || null,
    onFilter: (value, record) => record.recommendCode.includes(value),
    width: 80,
    sorter: (a, b) => a.recommendCode > b.recommendCode,
    sortOrder: sortedInfo.field === "recommendCode" ? sortedInfo.order : null,
  },
  {
    title: "실시간손익",
    dataIndex: "liveProfitNLoss",
    valueType: "money",
    search: false,
    width: 96,
    sorter: (a, b) => a.liveProfitNLoss - b.liveProfitNLoss,
    sortOrder: sortedInfo.field === "liveProfitNLoss" ? sortedInfo.order : null,
    render: (text) => {
      return <ValueCell value={text} />;
    },
  },
  {
    title: "평가손익",
    dataIndex: "totValuationGNL",
    valueType: "money",
    search: false,
    width: 96,
    sorter: (a, b) => a.totValuationGNL - b.totValuationGNL,
    sortOrder: sortedInfo.field === "totValuationGNL" ? sortedInfo.order : null,
    render: (text) => {
      return <ValueCell value={text} />;
    },
  },
  {
    title: "수수료",
    dataIndex: "totalFee",
    valueType: "money",
    search: false,
    width: 80,
    sorter: (a, b) => a.totalFee - b.totalFee,
    sortOrder: sortedInfo.field === "totalFee" ? sortedInfo.order : null,
    render: (text) => {
      return <ValueCell value={text} />;
    },
  },
  {
    title: "평가담보금",
    dataIndex: "liveUserBalance",
    search: false,
    valueType: "money",
    width: 96,
    sorter: (a, b) => a.liveUserBalance - b.liveUserBalance,
    sortOrder: sortedInfo.field === "liveUserBalance" ? sortedInfo.order : null,
    render: (text) => {
      return <ValueCell value={text} type={0} />;
    },
  },
  {
    title: "등급",
    dataIndex: "authority",
    valueType: "text",
    search: false,
    width: 80,
    filteredValue: filteredInfo.authority || null,
    filters: [
      {
        text: "준회원",
        value: 0,
      },
      {
        text: "정회원",
        value: 1,
      },
      {
        text: "모의투자",
        value: 4,
      },
    ],
    filterMode: "tree",
    filterSearch: false,
    onFilter: (value, record) => record.authority === value,
    render: (value) => {
      if (value === 0) return "준회원";
      if (value === 1) return "정회원";
      if (value === 4) return "모의투자";
    },
  },
];

// 회원 주문현황
export const ProfitStatus = React.memo((props) => {
  const { data, selectedRowKeys, handleSelectionChanged } = props;

  const [dataSource, setDataSource] = useState(data);
  // filter
  const [filteredInfo, setFilteredInfo] = useState({
    authority: [0, 1, 4],
  });
  // sorter
  const [sortedInfo, setSorteredInfo] = useState({});
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const handleTableOptionChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSorteredInfo(sorter);
  };
  const handleOnSearch = (value) => {
    const newDataSource = data.filter(
      (data) =>
        data.id.includes(value) ||
        data.userName.includes(value) ||
        data.recommendCode.includes(value)
    );
    setDataSource(newDataSource);

    setSearchValue("");
  };

  const handleReload = () => {
    setDataSource(data);
    setFilteredInfo({ authority: [0, 1, 4] });
    setSorteredInfo({});
  };

  const profitStautsColumn = getOrderStatusProfitStatusColumn(
    filteredInfo,
    sortedInfo
  );
  const handleSegmentedChanged = (value) => {
    if (value === "전체") {
      setFilteredInfo({ ...filteredInfo, authority: [0, 1, 4] });
    }
    if (value === "일반회원") {
      setFilteredInfo({ ...filteredInfo, authority: [0, 1] });
    }
    if (value === "테스터") {
      setFilteredInfo({ ...filteredInfo, authority: [4] });
    }
  };

  return (
    <ProCard>
      <div className="toolbox">
        <Space>
          <Button
            size="middle"
            type="primary"
            icon={<ReloadOutlined />}
            onClick={handleReload}
          >
            초기화
          </Button>
        </Space>
        <Search
          placeholder="아이디, 이름, 또는 추천인"
          allowClear
          enterButton="검색"
          style={{ width: 400 }}
          size="middle"
          onSearch={handleOnSearch}
          onChange={(event) => {
            setSearchValue(event.target.value);
          }}
          value={searchValue}
        />
      </div>
      <div>
        <Segmented
          options={["전체", "일반회원", "테스터"]}
          onChange={(value) => handleSegmentedChanged(value)}
          defaultValue="전체"
        />
      </div>
      <CustomBasicTable
        rowKey={"id"}
        columns={profitStautsColumn}
        dataSource={dataSource}
        onChange={handleTableOptionChange}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys,
          onChange: handleSelectionChanged,
        }}
      />
    </ProCard>
  );
});

const getCurrentPositionStatusColumn = () => [
  {
    title: "구분",
    dataIndex: "gubun",
    key: "gubun",
    width: 80,
  },
  {
    title: "선물(L/S)",
    dataIndex: "domestic",
    key: "domestic",
    width: 96,
  },
  {
    title: "콜옵션(L/S)",
    dataIndex: "callOption",
    key: "callOption",
    width: 96,
  },
  {
    title: "풋옵션(L/S)",
    dataIndex: "putOption",
    key: "putOption",
    width: 96,
  },
  {
    title: "해외선물(L/S)",
    dataIndex: "foreign",
    key: "foreign",
    width: 96,
  },
];

// 보유포지션
export const CurrentPositionStatus = React.memo((props) => {
  const { data } = props;
  const columns = getCurrentPositionStatusColumn();

  return (
    <Container>
      <h3>보유포지션</h3>
      <CustomBasicTable
        rowKey={"gubun"}
        columns={columns}
        dataSource={data}
        pagination={{ position: ["none", "none"] }}
        scroll={{ x: 493 }}
      />
    </Container>
  );
});

const getMemberPositionColumn = (concludeProps) => [
  { title: "아이디", dataIndex: "id", key: "id", width: 80 },
  { title: "이름", dataIndex: "userName", key: "userName", width: 80 },
  { title: "종목", dataIndex: "itemCode", key: "itemCode", width: 56 },
  {
    title: "구분",
    dataIndex: "isBuy",
    key: "isBuy",
    width: 40,
    render: (text) => {
      return <IsBuyCellRender value={text} />;
    },
  },
  { title: "수량", dataIndex: "count", key: "count", width: 40 },
  { title: "평균가", dataIndex: "price", key: "price", width: 64 },
  {
    title: "현재가",
    dataIndex: "currentPrice",
    key: "currentPrice",
    width: 64,
  },
  {
    title: "평가손익",
    dataIndex: "valuationGNL",
    key: "valuationGNL",
    width: 80,
    render: (text) => {
      return <ValueCell value={text} />;
    },
  },
  {
    title: "청산",
    key: "action",
    width: 64,
    className: "actionButton",
    render: (_, record) => {
      return <ConcludeButton {...concludeProps} data={record} />;
    },
  },
];

// 회원별 포지션
export const MemberPosition = React.memo((props) => {
  const { data } = props;
  const [concludeOrder] = useMutation(CONCLUDE_ORDER);
  const {
    user: { userId: managerId },
  } = useUserState();
  const columns = getMemberPositionColumn({
    managerId,
    concludeOrder,
  });
  return (
    <Container>
      <h3>회원별 포지션</h3>
      <CustomBasicTable
        columns={columns}
        dataSource={data}
        pagination={{ position: ["none", "none"] }}
        scroll={{ x: 600 }}
        rowKey={"orderNo"}
      />
    </Container>
  );
});

const getUnsignedPositionColumn = (props) => [
  { title: "아이디", dataIndex: "id", width: 80 },
  { title: "이름", dataIndex: "userName", width: 80 },
  { title: "종목", dataIndex: "itemCode", width: 56 },
  { title: "미체결", dataIndex: "type", width: 56 },
  {
    title: "구분",
    dataIndex: "isBuy",
    width: 40,
    render: (text) => {
      return <IsBuyCellRender value={text} />;
    },
  },
  { title: "수량", dataIndex: "count", width: 40 },
  { title: "주문가격", dataIndex: "price", width: 64 },
  {
    title: "취소",
    key: "action",
    className: "actionButton",
    width: 64,
    render: (_, record) => {
      return <CancelButton {...props} data={record} />;
    },
  },
];

// 미체결 포지션
export const UnsignedPositon = React.memo((props) => {
  const { data } = props;
  const [cancelOrder] = useMutation(CANCEL_ORDER);
  const {
    user: { userId: managerId },
  } = useUserState();
  const columns = getUnsignedPositionColumn({ managerId, cancelOrder });

  return (
    <Container>
      <h3>미체결 포지션</h3>
      <CustomBasicTable
        columns={columns}
        dataSource={data}
        pagination={{ position: ["none", "none"] }}
        scroll={{ x: 600 }}
        rowKey={"orderNo"}
      />
    </Container>
  );
});
