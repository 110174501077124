import { gql } from "@apollo/client";
export const SIGN_DATA = gql`
  fragment real_sign on ItemData {
    real_sign {
      code
      chetime
      price
      cgubun
      cvolume
      sign
      change
      drate
      open
      high
      low
      volume
    }
  }
`;

export const HOGA_DATA = gql`
  fragment real_hoga on ItemData {
    real_hoga {
      code
      hotime
      offerho1
      bidho1
      offerrem1
      bidrem1
      offercnt1
      bidcnt1
      offerho2
      bidho2
      offerrem2
      bidrem2
      offercnt2
      bidcnt2
      offerho3
      bidho3
      offerrem3
      bidrem3
      offercnt3
      bidcnt3
      offerho4
      bidho4
      offerrem4
      bidrem4
      offercnt4
      bidcnt4
      offerho5
      bidho5
      offerrem5
      bidrem5
      offercnt5
      bidcnt5
      totofferrem
      totbidrem
      totoffercnt
      totbidcnt
    }
  }
`;

export const ITEM_DATA = gql`
  fragment itemData on ItemData {
    code
    type
    hname
    month
    short_hname
    exch_name
    unit_price
    digit
    lastday_closeprice
    open_margin
    maintain_margin
    losscut
    listed_high_price
    listed_low_price
    product_type
    overnight_margin
    tick_value
    currency_code
    expiry_date
    d_day
    signRate
    clearRate
    basic_fee
    is_overnight_permit
    order_condition_type
    order_condition_value
    overnight_first_margin
    overnight_second_margin
    overnight_third_margin
    currency_data {
      id
      value
    }
    real_hoga {
      code
      hotime
      offerho1
      bidho1
      offerrem1
      bidrem1
      offercnt1
      bidcnt1
      offerho2
      bidho2
      offerrem2
      bidrem2
      offercnt2
      bidcnt2
      offerho3
      bidho3
      offerrem3
      bidrem3
      offercnt3
      bidcnt3
      offerho4
      bidho4
      offerrem4
      bidrem4
      offercnt4
      bidcnt4
      offerho5
      bidho5
      offerrem5
      bidrem5
      offercnt5
      bidcnt5
      totofferrem
      totbidrem
      totoffercnt
      totbidcnt
    }
    real_sign {
      code
      chetime
      price
      cgubun
      cvolume
      sign
      change
      drate
      open
      high
      low
      volume
    }
    trading_time {
      id
      name
      is_available1
      is_available2
      is_available3
      trade_start1
      trade_order_end1
      trade_clear_end1
      trade_start2
      trade_order_end2
      trade_clear_end2
      trade_start3
      trade_order_end3
      trade_clear_end3
      is_available_breaking
      breaking_start
      breaking_end
    }
  }
`;

export const FOREIGN_DATA = gql`
  fragment foreignItem on ForeignItem {
    basicCode
    clearRate
    code
    expiry_date
    fee
    id
    isAvailable
    isClosed
    is_overnight_permit
    lastUpdated
    losscut
    maintain_margin
    memo
    name
    next_code
    open_margin
    overnight_first_margin
    overnight_second_margin
    short_hname
    signRate
    trading_time_id
  }
`;

export const FOREIGN_CODES = gql`
  fragment foreignCode on ForeignCode {
    codes {
      basicCode
      hname
    }
  }
`;
