import { Statistic } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const CustomStatistic = ({
  title,
  value,
  valueStyle = {
    color: "#000",
  },
  type = 1,
}) => {
  const iconStyle = {
    fontSize: "14px",
    position: "relative",
    bottom: "4px",
  };

  if (value > 0) valueStyle.color = "#cf1322";
  if (type === 0 || value < 0) valueStyle.color = "#0958d9";
  if (type === 2) valueStyle.color = "#000";
  const prefix = () =>
    value === 0 || type === 2 ? (
      <div className="empty" />
    ) : value > 0 && type !== 0 ? (
      <PlusOutlined style={iconStyle} />
    ) : (
      <MinusOutlined style={iconStyle} />
    );

  return (
    <Statistic
      title={title}
      value={Math.abs(value)}
      prefix={prefix()}
      valueStyle={valueStyle}
    />
  );
};

export default CustomStatistic;
