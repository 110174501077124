import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
const GlobalStyle = createGlobalStyle`
    ${reset}
    body{
        padding: 0;
        margin: 0;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 10px;
    };
    
  .popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}


.ant-layout-sider, .ant-layout-header{
  background: none;
}

td.padding-0 {
  padding: 0 4px !important;
  &.custom{
    padding: 0 !important;
  }
}

`;

export default GlobalStyle;
