import React, { useEffect, useState } from "react";
import { MemberList } from "./MemberListPresenter";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import {
  CREATE_USER,
  GET_All_USER_DATA,
  GET_RECOMMENDER_INFO,
  GET_SERVER_LIST,
  REVISE_USERBALANCE,
  REVISE_USER_SETTING,
  SUBSCRIBE_CURRENTSTATE,
} from "../../../graphql/manageUser";
import { useUserState } from "../../../../context";
import { INQUIRY_DEPOSIT_ACCOUNT } from "../../../graphql/depositAccount";
import {
  Button,
  Row,
  Select,
  Form,
  Col,
  Input,
  Space,
  Checkbox,
  message,
} from "antd";
import { getUserData, writeUserData } from "../../../cache/userData";
import MemberListForm from "../MemberOrderHistory/MemberListForm";
import { mainClient } from "../../../../apollo";
import { ExcelExportButton } from "../../../common/CustomTable";
import exportExcel from "../../../common/exportExcel";
import dayjs from "dayjs";
import { getBankName } from "../../../common/data";
import { SUBSCRIBE_REQUEST_BANKING } from "../../../graphql/banking";

const Container = styled.div`
  .padding-0 {
    padding: 0;
  }
`;

const inquiryType = [
  { value: 0, label: "아이디" },
  { value: 1, label: "이름" },
  { value: 2, label: "핸드폰" },
  { value: 3, label: "이메일" },
  { value: 4, label: "추천인" },
  { value: 5, label: "아이피" },
  { value: 6, label: "맥주소" },
];

const initialValues = {
  id: "",
  userName: "",
  userPw: "",
  phoneNo: "",
  recommendCode: "",
  email: "",
  userState: 1,
  authority: 0,
  userAccountBank: 0,
  userAccountNo: "",
  userAccountOwner: "",
  deposit_accountId: 1,
  memo: "",
};
const refetchUserData = async () => {
  const {
    data: { getAllUserData },
  } = await mainClient.query({
    query: GET_All_USER_DATA,
    fetchPolicy: "no-cache",
    variables: {
      isAll: true,
      withTester: true,
    },
  });
  writeUserData(getAllUserData);

  return getUserData(1);
};

const MemberListTableContainer = styled.div`
  padding-top: 8px;
`;

export default () => {
  const [usersData, setUsersData] = useState(getUserData(1));
  const [messageApi, contextHolder] = message.useMessage();
  const [isOpen, setIsOpen] = useState(false);
  const [isAddNewMember, setIsAddNewMember] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const [form] = Form.useForm();
  const [drawerForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 20,
    showSizeChanger: false,
  });

  const {
    user: { userId: managerId },
  } = useUserState();

  const [reviseUserSetting] = useMutation(REVISE_USER_SETTING, {
    onCompleted: async (result) => {
      const { reviseUserSetting } = result;

      messageApi.success(`${reviseUserSetting.id}님의 회원정보 수정 완료`);

      setUsersData(await refetchUserData());

      setIsOpen(false);
    },
  });
  const [reviseUserBalance] = useMutation(REVISE_USERBALANCE, {
    onCompleted: async ({ reviseUserBalance }) => {
      if (reviseUserBalance === 200) messageApi.success("관리자 입출금완료");
      if (reviseUserBalance === 404) messageApi.error("관리자 입출금실패");
    },
  });
  const [managerCreateUser] = useMutation(CREATE_USER, {
    onCompleted: async ({ createUserByManager }) => {
      if (createUserByManager.code === 200) {
        setUsersData(await refetchUserData());
        setIsOpen(false);
        return messageApi.success(`
        ${createUserByManager.data.id} 회원등록 완료`);
      }

      if (createUserByManager.code === 409) {
        return messageApi.error("이미 존재하는 아이디입니다.");
      }

      return messageApi.error("오류로 아이디를 생성할 수 없습니다.");
    },
  });

  useEffect(() => {
    const subscribeCurrentState = mainClient
      .subscribe({
        query: SUBSCRIBE_CURRENTSTATE,
      })
      .subscribe(({ data }) => {
        const targetData = data.subscribeCurrentState;

        if (targetData.id) {
          const { id } = targetData;
          const convertedData = usersData.map((user) => {
            if (user.id === id) {
              return {
                ...user,
                ...targetData,
              };
            } else {
              return user;
            }
          });

          setUsersData(convertedData);
          drawerForm.setFieldsValue(targetData);
        }
      });

    return () => subscribeCurrentState.unsubscribe();
  }, [drawerForm]);
  const checkBoxHandler = (e) => {
    if (e.target.checked) {
      return setUsersData(getUserData(0));
    }
    setUsersData(getUserData(1));
  };

  const handleOnFinish = (data) => {
    if (data.userState) {
      // 회원정보수정
      let revisedData = {};
      for (let key in data) {
        if (
          data.hasOwnProperty(key) &&
          formValues.hasOwnProperty(key) &&
          data[key] !== formValues[key]
        ) {
          revisedData[key] = data[key];
        }
      }

      reviseUserSetting({
        variables: { ...revisedData, managerId, userId: data.id },
      });
    } else {
      // 회원추가
      managerCreateUser({
        variables: data,
      });
    }
  };
  const inquiryHandler = (data) => {
    const { inquiryType, inquiryValue } = data;
    const users = getUserData(1);

    let filtered = [];
    if (inquiryType === 0) {
      filtered = users.filter(
        (user) => user.id && user.id.includes(inquiryValue)
      );
    }
    if (inquiryType === 1) {
      filtered = users.filter(
        (user) => user.userName && user.userName.includes(inquiryValue)
      );
    }
    if (inquiryType === 2) {
      filtered = users.filter(
        (user) => user.phoneNo && user.phoneNo.includes(inquiryValue)
      );
    }
    if (inquiryType === 3) {
      filtered = users.filter(
        (user) => user.email && user.email.includes(inquiryValue)
      );
    }
    if (inquiryType === 4) {
      filtered = users.filter(
        (user) => user.recommendCode && user.recommendCode === inquiryValue
      );
    }
    if (inquiryType === 5) {
      filtered = users.filter(
        (user) => user.connectIP && user.connectIP.includes(inquiryValue)
      );
    }
    if (inquiryType === 6) {
      filtered = users.filter(
        (user) => user.connectMAC && user.connectMAC.includes(inquiryValue)
      );
    }

    setUsersData(filtered);
  };
  const handleOnClick = (data) => {
    setFormValues({
      ...data,
      optionSellCount: Math.floor(data.option_maxAvailableCount / 5),
    });
    setIsAddNewMember(false);
    setIsOpen(true);
  };

  const handleExporting = () => {
    const convertedData = usersData.map((user) => {
      const {
        createdAt,
        lastLogin,
        lastTrade,
        userState,
        deposit_accountBank,
        userAccountBank,
        isOverDomestic,
        updatedData_overDomestic,
        isOverOption,
        updatedData_overOption,
        isOverForeign,
        updatedData_overForeign,
        isOverHangSeng,
        updatedData_overHangseng,
        isOverMiniForeign,
        updatedData_miniForeign,
      } = user;
      let authority = "준회원";
      if (user.authority === 1) authority = "정회원";
      if (user.authority === 2) authority = "추천인";
      if (user.authority === 3) authority = "관리자";
      if (user.authority === 4) authority = "테스터";
      if (user.authority === 5) authority = "모의투자";

      return {
        ...user,
        createdAt: createdAt
          ? dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss")
          : null,
        lastLogin: lastLogin
          ? dayjs(lastLogin).format("YYYY-MM-DD HH:mm:ss")
          : null,
        lastTrade: lastTrade
          ? dayjs(lastTrade).format("YYYY-MM-DD HH:mm:ss")
          : null,
        authority: authority,
        userState: userState === 1 ? "정상" : "블랙",
        deposit_accountBank: getBankName(deposit_accountBank),
        userAccountBank: getBankName(userAccountBank),
        isOverDomestic: isOverDomestic ? "허용" : "불가",
        isOverOption: isOverOption ? "허용" : "불가",
        isOverForeign: isOverForeign ? "허용" : "불가",
        isOverHangSeng: isOverHangSeng ? "허용" : "불가",
        isOverMiniForeign: isOverMiniForeign ? "허용" : "불가",
        updatedData_overDomestic: updatedData_overDomestic
          ? dayjs(updatedData_overDomestic).format("YYYY-MM-DD HH:mm:ss")
          : null,
        updatedData_overOption: updatedData_overOption
          ? dayjs(updatedData_overOption).format("YYYY-MM-DD HH:mm:ss")
          : null,
        updatedData_overForeign: updatedData_overForeign
          ? dayjs(updatedData_overForeign).format("YYYY-MM-DD HH:mm:ss")
          : null,
        updatedData_overHangseng: updatedData_overHangseng
          ? dayjs(updatedData_overHangseng).format("YYYY-MM-DD HH:mm:ss")
          : null,
        updatedData_miniForeign: updatedData_miniForeign
          ? dayjs(updatedData_miniForeign).format("YYYY-MM-DD HH:mm:ss")
          : null,
      };
    });

    exportExcel("회원목록", convertedData);
  };

  return (
    <Container>
      {contextHolder}
      <MemberListForm
        initialValues={formValues}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleOnFinish={handleOnFinish}
        isAddNewMember={isAddNewMember}
        managerId={managerId}
        reviseUserBalance={reviseUserBalance}
        form={drawerForm}
        messageApi={messageApi}
      />
      <Row justify={"space-between"}>
        <Col flex={"auto"}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setFormValues(initialValues);
                setIsAddNewMember(true);
                setIsOpen(true);
              }}
            >
              신규회원등록
            </Button>
            <Checkbox onChange={checkBoxHandler}>테스터만</Checkbox>
            <ExcelExportButton handleExporting={handleExporting} />
          </Space>
        </Col>
        <Col flex={"354px"}>
          <Form
            form={form}
            initialValues={{
              inquiryType: 0,
              inquiryValue: "",
            }}
            layout="inline"
            onFinish={inquiryHandler}
          >
            <Form.Item name="inquiryType">
              <Select options={inquiryType} popupMatchSelectWidth={120} />
            </Form.Item>
            <Form.Item name="inquiryValue">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                조회
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <MemberListTableContainer>
        <MemberList
          dataSource={usersData}
          pagination={pagination}
          handleOnClick={handleOnClick}
        />
      </MemberListTableContainer>
    </Container>
  );
};
