import {
  DrawerForm,
  ProFormText,
  ProFormGroup,
  ProFormSelect,
  ProFormDigit,
  ProFormSwitch,
  ProFormTextArea,
} from "@ant-design/pro-components";

import React, { useState } from "react";
import { Form, Select, Button, Space, InputNumber } from "antd";
import { bankCategory, memberGradeDatas } from "../../../common/data";
import {
  getDepositAccount,
  getRecommendList,
  getServerList,
} from "../../../cache/commonData";
import { ValueCell } from "../../../common/CustomCell";
import styled from "styled-components";

const BalanceContainer = styled.div`
  display: flex;
`;

// 관리자 입출금부분
const UserBalanceController = (props) => {
  const { reviseUserBalance, form, managerId, messageApi } = props;
  const [depositValue, setDepositValue] = useState(0);
  const [withdrawalValue, setWithdrawalValue] = useState(0);

  const handleClickReviseUserBalance = (isDeposit) => {
    const userId = form.getFieldValue("id");

    if (
      (isDeposit && depositValue.current < 1) ||
      (!isDeposit && withdrawalValue.current < 1)
    ) {
      messageApi.error("0원보다 큰 수를 입력해주세요");
      return;
    }

    reviseUserBalance({
      variables: {
        managerId,
        userId,
        isDeposit,
        value: isDeposit ? depositValue : withdrawalValue,
      },
    });
    if (isDeposit) {
      setDepositValue(0);
    }
    if (!isDeposit) {
      setWithdrawalValue(0);
    }
  };

  return (
    <ProFormGroup title="관리자 입/출금">
      <ProFormText
        name="userBalance"
        label="잔고"
        readonly
        render={(value) => (
          <BalanceContainer>
            <span>₩</span>
            <ValueCell value={value} type={0} />
          </BalanceContainer>
        )}
      />
      <Space align="flex-start">
        <InputNumber
          locale="ko-KR"
          addonBefore={<div>추가입금금액</div>}
          onChange={(value) => setDepositValue(value)}
          prefix={"₩"}
          formatter={(value) => {
            const numberValue = parseInt(value);
            if (isNaN(numberValue) || numberValue < 0) return 0;
            return parseInt(value).toLocaleString();
          }}
          value={depositValue}
        />
        <Button
          onClick={() => handleClickReviseUserBalance(true)}
          type="primary"
        >
          실행
        </Button>
      </Space>
      <Space align="flex-start">
        <InputNumber
          locale="ko-KR"
          addonBefore={<div>추가출금금액</div>}
          value={withdrawalValue}
          onChange={(value) => {
            setWithdrawalValue(value);
          }}
          prefix={"₩"}
          formatter={(value) => {
            const numberValue = parseInt(value);
            if (isNaN(numberValue) || numberValue < 0) return 0;
            return parseInt(value).toLocaleString();
          }}
        />
        <Button
          onClick={() => handleClickReviseUserBalance(false)}
          type="primary"
        >
          실행
        </Button>
      </Space>
    </ProFormGroup>
  );
};

const MemberListForm = (props) => {
  // const depositValue = useRef(0);
  // const withdrawalValue = useRef(0);

  const {
    initialValues,
    isOpen,
    setIsOpen,
    handleOnFinish,
    isAddNewMember,
    managerId,
    reviseUserBalance,
    form,
    messageApi,
  } = props;

  const serverList = getServerList();

  const recommendList = getRecommendList().map((item) => {
    return {
      value: item.recommendCode,
      label: item.recommendCode,
    };
  });

  const depositAccountList = getDepositAccount().map((item) => {
    return { value: item.id, label: item.accountName };
  });

  const handleDrawerOpen = (isOpen) => {
    if (isOpen) {
      form.setFieldsValue({
        ...initialValues,
      });
    }
    setIsOpen(isOpen);
  };

  return (
    <DrawerForm
      id="reviseMemberData"
      name={"reviseMemberData"}
      form={form}
      onOpenChange={(isOpen) => handleDrawerOpen(isOpen)}
      open={isOpen}
      drawerProps={{
        destroyOnClose: true,
      }}
      title={isAddNewMember ? "신규회원등록" : "회원정보수정"}
      onFinish={handleOnFinish}
      initialValues={initialValues}
      width={960}
      layout="horizontal"
    >
      <ProFormGroup title="기본정보">
        <ProFormText
          name="id"
          label="아이디"
          width="sm"
          readonly={!isAddNewMember}
        />
        <ProFormText name="userName" label="이름" width="sm" />
        <ProFormText name="userPw" label="패스워드" width="sm" />
        <ProFormText name="phoneNo" label="휴대폰" width="sm" />
        <ProFormText name="email" label="이메일" width="sm" />
        <ProFormSelect
          name="recommendCode"
          label="추천인"
          width="sm"
          options={recommendList}
        />
        {!isAddNewMember && (
          <ProFormSelect
            name="userState"
            label="계정상태"
            width="xs"
            fieldProps={{
              options: [
                { value: 0, label: "블랙" },
                { value: 1, label: "정상" },
                { value: 2, label: "거래정지" },
              ],
            }}
          />
        )}
        <ProFormSelect
          name="authority"
          label="등급(권한)"
          width="xs"
          options={memberGradeDatas.filter((item) => item.value !== 3)}
        />
        <Form.Item label="출금은행" name="userAccountBank" width="sm">
          <Select options={bankCategory} popupMatchSelectWidth={160} />
        </Form.Item>

        <ProFormText name="userAccountNo" label="출금계좌" width="sm" />
        <ProFormText name="userAccountOwner" label="출금예금주" width="xs" />
        <ProFormSelect
          width="xs"
          name="deposit_accountId"
          label="입금계좌"
          options={depositAccountList}
        />
        <ProFormTextArea name="memo" label="메모" width={844} height={200} />
      </ProFormGroup>
      {!isAddNewMember && (
        <>
          <UserBalanceController
            managerId={managerId}
            reviseUserBalance={reviseUserBalance}
            messageApi={messageApi}
            form={form}
          />
          <ProFormGroup title="국내선물">
            <ProFormDigit
              label="수수료"
              name="domestic_fee"
              fieldProps={{ precision: 4 }}
            />
            <ProFormDigit
              label="최대계약수"
              name="domestic_maxAvailableCount"
              fieldProps={{ precision: 0 }}
            />
            <ProFormSwitch
              label="오버나잇가능"
              name="isOverDomestic"
              checkedChildren="가능"
              unCheckedChildren="불가능"
            />
          </ProFormGroup>
          <ProFormGroup title="옵션">
            <ProFormDigit label="수수료" name="option_fee" />
            <ProFormDigit
              label="최대계약수"
              name="option_maxAvailableCount"
              onChange={(value) => {
                form.setFieldValue("optionSellCount", Math.floor(value / 5));
              }}
            />
            <ProFormSwitch
              label="오버나잇가능"
              name="isOverOption"
              checkedChildren="가능"
              unCheckedChildren="불가능"
            />
            <ProFormDigit label="매도계약수" name="optionSellCount" readonly />
          </ProFormGroup>
          <ProFormGroup title="해외선물">
            <ProFormDigit label="수수료" name="foreign_fee" />
            <ProFormDigit label="최대계약수" name="foreign_maxAvailableCount" />
            <ProFormSwitch
              label="오버나잇가능"
              name="isOverForeign"
              checkedChildren="가능"
              unCheckedChildren="불가능"
            />
          </ProFormGroup>
          <ProFormGroup title="항셍">
            <ProFormDigit label="수수료" name="hangseng_fee" />
            <ProFormDigit
              label="최대계약수"
              name="hangseng_maxAvailableCount"
            />
            <ProFormSwitch
              label="오버나잇가능"
              name="isOverHangSeng"
              checkedChildren="가능"
              unCheckedChildren="불가능"
            />
          </ProFormGroup>
          <ProFormGroup title="마이크로 해외선물">
            <ProFormDigit label="수수료" name="mini_foreign_fee" />
            <ProFormDigit
              label="최대계약수"
              name="mini_foreign_maxAvailableCount"
            />
            <ProFormSwitch
              label="오버나잇가능"
              name="isOverMiniForeign"
              checkedChildren="가능"
              unCheckedChildren="불가능"
            />
          </ProFormGroup>
          <ProFormGroup title="추천인별 설정">
            <ProFormSelect
              label="수수료"
              options={recommendList}
              onChange={(value) => {
                const {
                  domestic_fee,
                  foreign_fee,
                  hangseng_fee,
                  option_fee,
                  mini_foreign_fee,
                } = getRecommendList().filter(
                  (data) => data.recommendCode === value
                )[0];

                form.setFieldsValue({
                  domestic_fee,
                  foreign_fee,
                  hangseng_fee,
                  option_fee,
                  mini_foreign_fee,
                });
              }}
            />
            <ProFormSelect
              label="거래가능개수"
              options={recommendList}
              onChange={(value) => {
                const {
                  domestic_maxAvailableCount,
                  option_maxAvailableCount,
                  foreign_maxAvailableCount,
                  hangseng_maxAvailableCount,
                  mini_foreign_maxAvailableCount,
                } = getRecommendList().filter(
                  (data) => data.recommendCode === value
                )[0];

                form.setFieldsValue({
                  domestic_maxAvailableCount,
                  option_maxAvailableCount,
                  foreign_maxAvailableCount,
                  hangseng_maxAvailableCount,
                  mini_foreign_maxAvailableCount,
                });
              }}
            />
          </ProFormGroup>
        </>
      )}
    </DrawerForm>
  );
};

export default MemberListForm;
