import { gql } from "@apollo/client";

export const INITIALIZE_REALTIME_REQUEST = gql`
  query InitializeRealTimeRequest {
    initializeRealTimeRequest {
      registerUserData {
        id
      }
      requestBankingData {
        id
        isDeposit
        userId
      }
    }
  }
`;

export const REQUEST_REGISTER = gql`
  subscription RequestRegister {
    requestRegister {
      id
    }
  }
`;
