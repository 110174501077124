﻿import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
  ApolloLink,
} from "@apollo/client";

import { onError } from "@apollo/client/link/error";

import fetch from "cross-fetch";

import { getMainDefinition } from "@apollo/client/utilities";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

export const httpLink = createHttpLink({
  uri: `https://${process.env.GRAPHQLSERVER}/graphql`,
  fetch,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    console.log(networkError, "networkError");
  }
});

const userLink = ApolloLink.from([errorLink, httpLink]);
export const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${process.env.GRAPHQLSERVER}/subscriptions`,
  })
);

const options = {
  typePolices: {
    BasicInfo: {
      keyFields: ["code"],
    },

    User: {
      keyFields: ["id"],
    },
    UserData: {
      keyFields: ["id"],
      fields: {
        position: {
          merge(_, incoming) {
            return incoming;
          },
        },
      },
    },
  },
};

export const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  userLink
);

const cache = new InMemoryCache(options);

export const mainClient = new ApolloClient({
  link: splitLink,
  cache,
  connectToDevTools: true,
});
