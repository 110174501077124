import React from "react";

import { Dropdown } from "antd";
function linkToPageWithQuery(name, value, target) {
  let idx = window.location.href.lastIndexOf("/") + 1;

  const href =
    window.location.href.substring(0, idx) + `${target}?${name}=${value}`;

  window.open(href, "_blank");
}

function copyToClipboard(text) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied to clipboard");
    })
    .catch((err) => {
      console.error("Could not copy text: ", err);
    });
}

const contextMenu = ({ id, isWithdrawal, accountNo }) => {
  const menu = [
    {
      key: "orderSignedHistory",
      label: "주문내역",
      onClick: () => linkToPageWithQuery("id", id, "orderSignedHistory"),
    },
    {
      key: "memberOrderHistory",
      label: "액션로그",
      onClick: () => linkToPageWithQuery("id", id, "memberOrderHistory"),
    },
    {
      key: "memberDailyProfitNLoss",
      label: "회원일별손익",
      onClick: () => linkToPageWithQuery("id", id, "memberDailyProfitNLoss"),
    },
    {
      key: "depositNWithdrawalInquiry",
      label: "입출금내역",
      onClick: () => linkToPageWithQuery("id", id, "depositNWithdrawalInquiry"),
    },
  ];

  if (isWithdrawal)
    menu.push({
      key: "copy",
      label: `출금계좌복사(${accountNo})`,
      onClick: () => {
        copyToClipboard(accountNo);
      },
    });

  return menu;
};

const ContextMenu = (props) => {
  const { open, setOpen, contextUser, position } = props;

  return (
    <Dropdown
      overlayStyle={{
        position: "fixed",
        top:
          position.y < window.innerHeight - 175 ? position.y : position.y - 170,
        left: position.x,
      }}
      menu={{ items: contextMenu(contextUser) }}
      trigger={["contextMenu"]}
      open={open}
      destroyPopupOnHide={true}
      onOpenChange={(flag) => setOpen(flag)}
    >
      <div />
    </Dropdown>
  );
};

export default ContextMenu;
