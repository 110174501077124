import {
  TableOutlined,
  ProfileOutlined,
  UserOutlined,
  IdcardOutlined,
  TeamOutlined,
  WalletOutlined,
  FolderOpenOutlined,
  ToolOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { Badge } from "antd";

const getMenuItems = (newRegisterCount = 0, newBankingRequestCount = 0) => [
  {
    type: "group",
    label: "회원관리",
    icon: null,
    children: [
      { type: "divider" },
      { label: "현황판", key: "main", icon: <TableOutlined /> },
      {
        label: "주문별 현황판",
        key: "orderStatusByItem",
        icon: <TableOutlined />,
      },
      {
        label: "회원별 주문현황",
        key: "orderStatus",
        icon: <ProfileOutlined />,
      },
      { label: "회원목록", key: "memberList", icon: <UserOutlined /> },
      {
        label:
          newRegisterCount > 0 ? (
            <>
              회원가입신청현황
              <Badge count={newRegisterCount} offset={[10, -20]} />
            </>
          ) : (
            "회원가입신청현황"
          ),
        key: "registerStatus",
        icon: <IdcardOutlined />,
      },

      {
        label: "추천인 관리",
        key: "manageRecommender",
        icon: <TeamOutlined />,
      },
      {
        label: "이상거래 감지",
        key: "checkAbuse",
        icon: <ToolOutlined />,
      },
    ],
  },

  {
    type: "group",
    label: "입출금관리",
    icon: null,
    children: [
      { type: "divider" },
      {
        label:
          newBankingRequestCount > 0 ? (
            <>
              입출금신청현황
              <Badge count={newBankingRequestCount} offset={[10, -20]} />
            </>
          ) : (
            "입출금신청현황"
          ),
        key: "depositNWithdrawalStatus",
        icon: <WalletOutlined />,
      },
      {
        label: "입금 공지사항",
        key: "depositNotice",
        icon: <NotificationOutlined />,
      },
      {
        label: "입출금내역조회",
        key: "depositNWithdrawalInquiry",
        icon: <TableOutlined />,
      },
      {
        label: "입출금통계",
        key: "depositNWithdrawal",
        icon: <TableOutlined />,
      },
    ],
  },
  {
    type: "group",
    label: "내역조회",
    icon: null,
    children: [
      { type: "divider" },
      {
        label: "전체손익내역",
        key: "totalProfitNLoss",
        icon: <FolderOpenOutlined />,
      },
      {
        label: "추천인별 손익내역",
        key: "recommendedProfitNLoss",
        icon: <FolderOpenOutlined />,
      },
      {
        label: "회원 총손익 집계내역",
        key: "memberTotalProfitNLoss",
        icon: <FolderOpenOutlined />,
      },
      {
        label: "회원 일별손익내역",
        key: "memberDailyProfitNLoss",
        icon: <FolderOpenOutlined />,
      },
      {
        label: "주문체결내역",
        key: "orderSignedHistory",
        icon: <FolderOpenOutlined />,
      },
      {
        label: "클라이언트 로그",
        key: "memberOrderHistory",
        icon: <FolderOpenOutlined />,
      },
      {
        label: "회원담보금보정내역",
        key: "MemberAccountBalanceReviseHistory",
        icon: <FolderOpenOutlined />,
      },
    ],
  },
  {
    type: "group",
    label: "이력조회",
    icon: null,
    children: [
      { type: "divider" },
      {
        label: "실시간 접속내역",
        key: "liveTimeLoginHistory",
        icon: <FolderOpenOutlined />,
      },
      {
        label: "회원접속이력 조회",
        key: "loginHisotryInquiry",
        icon: <FolderOpenOutlined />,
      },
      {
        label: "공지사항",
        key: "notice",
        icon: <NotificationOutlined />,
      },
      {
        label: "관리자 접속 이력조회",
        key: "managerLoginHistory",
        icon: <FolderOpenOutlined />,
      },
      {
        label: "관리자 작업 이력조회",
        key: "managerWorkHistory",
        icon: <FolderOpenOutlined />,
      },
    ],
  },
  {
    type: "group",
    label: "계좌관련",
    icon: null,
    children: [
      { type: "divider" },
      {
        label: "입금계좌관리",
        key: "manageDepositAccount",
        icon: <WalletOutlined />,
      },
      {
        label: "거래종목관리(국내)",
        key: "manageDomestic",
        icon: <ToolOutlined />,
      },
      {
        label: "거래종목관리(해외)",
        key: "manageForeign",
        icon: <ToolOutlined />,
      },
      {
        label: "거래시간관리(국내)",
        key: "manageDomesticTradingTime",
        icon: <ToolOutlined />,
      },
      {
        label: "거래시간관리(해외)",
        key: "manageForeignTradingTime",
        icon: <ToolOutlined />,
      },
      {
        label: "비밀번호변경",
        key: "changePassword",
      },
    ],
  },
];

export default getMenuItems;
