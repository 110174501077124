import { mainClient } from "../../apollo";
import { INQUIRY_DEPOSIT_ACCOUNT } from "../graphql/depositAccount";
import { GET_RECOMMENDER_INFO, GET_SERVER_LIST } from "../graphql/manageUser";

export const getServerList = () => {
  const { getServerList } = mainClient.readQuery({
    query: GET_SERVER_LIST,
  });
  return getServerList.data;
};

export const getDepositAccount = () => {
  const { inquiryDepositAccount } = mainClient.readQuery({
    query: INQUIRY_DEPOSIT_ACCOUNT,
  });

  return inquiryDepositAccount;
};

export const getRecommendList = () => {
  const { getRecommenderInfo } = mainClient.readQuery({
    query: GET_RECOMMENDER_INFO,
  });

  return getRecommenderInfo;
};
