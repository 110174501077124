import React, { useState, useRef, useEffect } from "react";
import { ValueCell } from "../../../common/CustomCell";
import { ProCard } from "@ant-design/pro-components";
import CustomStatistic from "../../../common/CustomStatistic";
import { Space, Button, Input, Row, Col } from "antd";
const { Divider } = ProCard;
const { Search } = Input;
import { CustomBasicTable } from "../../../common/CustomTable";
import { ReloadOutlined } from "@ant-design/icons";
import _ from "lodash";
const getProfitStatusColumn = (filteredInfo, sortedInfo) => [
  {
    title: "아이디",
    dataIndex: "id",
    valueType: "text",
    fixed: "left",
    width: 200,
    sorter: (a, b) => a.id > b.id,
    sortOrder: sortedInfo.field === "id" ? sortedInfo.order : null,
  },
  {
    title: "이름",
    dataIndex: "userName",
    valueType: "text",
    width: 120,
    sorter: (a, b) => a.userName > b.userName,
    sortOrder: sortedInfo.field === "userName" ? sortedInfo.order : null,
  },
  {
    title: "추천인",
    dataIndex: "recommendCode",
    valueType: "text",
    width: 120,
    sorter: (a, b) => a.recommendCode > b.recommendCode,
    sortOrder: sortedInfo.field === "recommendCode" ? sortedInfo.order : null,
  },
  {
    title: "실시간손익",
    dataIndex: "liveProfitNLoss",
    valueType: "money",
    search: false,
    width: 160,
    sorter: (a, b) => a.liveProfitNLoss - b.liveProfitNLoss,
    sortOrder: sortedInfo.field === "liveProfitNLoss" ? sortedInfo.order : null,
    render: (text) => {
      return <ValueCell value={text} />;
    },
  },
  {
    title: "평가손익",
    dataIndex: "totValuationGNL",
    valueType: "money",
    search: false,
    width: 160,
    sorter: (a, b) => a.totValuationGNL - b.totValuationGNL,
    sortOrder: sortedInfo.field === "totValuationGNL" ? sortedInfo.order : null,
    render: (text) => {
      return <ValueCell value={text} />;
    },
  },
  {
    title: "수수료",
    dataIndex: "totalFee",
    valueType: "money",
    search: false,
    width: 160,
    sorter: (a, b) => a.totalFee - b.totalFee,
    sortOrder: sortedInfo.field === "totalFee" ? sortedInfo.order : null,
    render: (text) => {
      return <ValueCell value={text} type={1} />;
    },
  },
  {
    title: "평가담보금",
    dataIndex: "liveUserBalance",
    search: false,
    valueType: "money",
    width: 160,
    sorter: (a, b) => a.liveUserBalance - b.liveUserBalance,
    sortOrder: sortedInfo.field === "liveUserBalance" ? sortedInfo.order : null,
    render: (text) => {
      return <ValueCell value={text} type={0} />;
    },
  },
  {
    title: "등급",
    dataIndex: "authority",
    valueType: "number",
    search: false,
    width: 120,
    filteredValue: filteredInfo.authority || null,
    filters: [
      {
        text: "준회원",
        value: 0,
      },
      {
        text: "정회원",
        value: 1,
      },
      {
        text: "모의투자",
        value: 4,
      },
    ],
    filterMode: "tree",
    filterSearch: false,
    onFilter: (value, record) => record.authority === value,
    render: (value) => {
      if (value === 0) return "준회원";
      if (value === 1) return "정회원";
      if (value === 4) return "모의투자";
    },
  },
];

const ProfitStatusSum = (props) => {
  const { data } = props;
  const {
    liveProfitNLoss,
    totValuationGNL,
    profitNLoss,
    totalFee,
    liveUserBalance,
  } = data;

  return (
    <ProCard.Group title="합계" direction={"row"} style={{ marginBottom: 16 }}>
      <ProCard>
        <CustomStatistic title="실시간손익" value={liveProfitNLoss} />
      </ProCard>
      <Divider type={"vertical"} />
      <ProCard>
        <CustomStatistic title="평가손익" value={totValuationGNL} />
      </ProCard>
      <Divider type={"vertical"} />
      <ProCard>
        <CustomStatistic title="실현손익" value={profitNLoss} />
      </ProCard>
      <Divider type={"vertical"} />
      <ProCard>
        <CustomStatistic title="수수료" value={totalFee} type={0} />
      </ProCard>
      <Divider type={"vertical"} />
      <ProCard>
        <CustomStatistic title="평가담보금" value={liveUserBalance} type={2} />
      </ProCard>
    </ProCard.Group>
  );
};

const ProfitStatus = (props) => {
  const { data, sum } = props;

  const tableRef = useRef(null);
  const [dataSource, setDataSource] = useState(data);

  const [filteredInfo, setFilteredInfo] = useState({
    authority: [0, 1],
    searchValue: "",
  });
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const handleTableOptionChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    const filteredData = newDataSource(data, searchValue);
    setDataSource(filteredData);
  }, [data, searchValue]);

  const newDataSource = (data, value) =>
    data.filter((data) => {
      return (
        data.id.includes(value) ||
        data.userName.includes(value) ||
        data.recommendCode.includes(value)
      );
    });
  const handleOnSearch = (value) => {
    setFilteredInfo({ ...filteredInfo, searchValue: value });
    setSearchValue("");
  };
  const handleReload = () => {
    setDataSource(data);
    setFilteredInfo({});
    setSortedInfo({});
    setSearchValue("");
  };

  const profitStautsColumn = getProfitStatusColumn(filteredInfo, sortedInfo);
  return (
    <Row>
      <Col span={24}>
        <ProfitStatusSum data={sum} />
      </Col>
      <Col span={24}>
        <ProCard>
          <div className="toolbox">
            <Space>
              <Button
                size="middle"
                type="primary"
                icon={<ReloadOutlined />}
                onClick={handleReload}
              >
                초기화
              </Button>
            </Space>
            <Search
              placeholder="아이디, 이름, 또는 추천인"
              allowClear
              enterButton="검색"
              style={{ width: 400 }}
              size="middle"
              onSearch={handleOnSearch}
              onChange={(event) => {
                setSearchValue(event.target.value);
              }}
              value={searchValue}
            />
          </div>
          <CustomBasicTable
            rowKey={"id"}
            columns={profitStautsColumn}
            dataSource={dataSource}
            onChange={handleTableOptionChange}
            ref={tableRef}
          />
        </ProCard>
      </Col>
    </Row>
  );
};

const isEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default React.memo(ProfitStatus, isEqual);
