import React, { useState } from "react";
import styled from "styled-components";

import { Input, Button, Tooltip } from "antd";
import moment from "moment";
import { getItemInfo } from "../cache/itemData";
const formatNumber = (value) => new Intl.NumberFormat().format(value);
const ValueCellContainer = styled.div`
  color: ${(props) => props.color};
  text-align: right;
  margin-right: 4px;
`;

const StatusCellContainer = styled.div`
  background-color: ${(props) =>
    props.data === 0 ? "#ff9800" : props.data === 1 ? "#4caf50" : "#f44336"};
  color: #fff;
  padding: 2px 0;
  text-align: center;
`;

const MemberGradeCellContainer = styled.div`
  background-color: ${(props) => (props.data === 0 ? "#ff9800" : "initial")};
  color: ${(props) => (props.data === 0 ? "#fff" : "initial")};

  text-align: center;
`;

const ApproveButtonContainer = styled.div`
  text-align: center;
  color: ${(props) => props.color};
`;

const StringValueCellContainer = styled.div`
  text-align: ${(props) => props.alignment};
`;

export const ValueCell = (props) => {
  const { value = 0, type } = props;

  let colorData = value > 0 ? "#cf1322" : value < 0 ? "#0958d9" : "#000";

  if (type === 0) colorData = "#000";
  if (type === 1) colorData = "#0958d9";
  return (
    <ValueCellContainer color={colorData}>
      {value !== null ? value.toLocaleString() : 0}
    </ValueCellContainer>
  );
};

export const StringValueCell = ({ value, alignment = "center" }) => {
  return (
    <StringValueCellContainer alignment={alignment}>
      {value.toFixed(0)}
    </StringValueCellContainer>
  );
};

// 원화 cellRenderer
export const ValueCellRender = (props) => {
  const { displayValue, value, color } = props;

  const colorData =
    typeof color === "string"
      ? color
      : value < 0 || displayValue < 0
      ? "#0000ff"
      : value > 0 || displayValue > 0
      ? "#ff0000"
      : "#000";

  return (
    <ValueCellContainer color={colorData}>
      {displayValue
        ? displayValue.toLocaleString()
        : value
        ? value.toLocaleString()
        : 0}
    </ValueCellContainer>
  );
};

// 상태 cellRenderer
export const StatusCellRender = (props) => {
  const { value } = props;
  const displayValue = value === 0 ? "승인대기" : value === 1 ? "승인" : "거절";

  return <StatusCellContainer data={value}>{displayValue}</StatusCellContainer>;
};

// 회원등급
export const MemberGradeCellRender = (props) => {
  const { value } = props;

  const displayValue =
    value === 0
      ? "준회원"
      : value === 1
      ? "정회원"
      : value === 2
      ? "추천인"
      : value === 3
      ? "관리자"
      : value === 4
      ? "테스터"
      : value === 5
      ? "모의투자"
      : value;

  const cellContainerValue =
    displayValue === "준회원"
      ? 0
      : displayValue === "정회원"
      ? 1
      : displayValue === "추천인"
      ? 2
      : displayValue === "관리자"
      ? 3
      : displayValue === "테스터"
      ? 4
      : displayValue === "모의투자"
      ? 5
      : 6;
  return (
    <MemberGradeCellContainer data={cellContainerValue}>
      {displayValue}
    </MemberGradeCellContainer>
  );
};

// member
export const MemberGradeCell = ({ value }) => {
  return (
    <MemberGradeCellContainer data={value === "준회원" ? 0 : 1}>
      {value}
    </MemberGradeCellContainer>
  );
};

// 승인 cellRenderer
export const ApproveCellRender = (props) => {
  const {
    data: { id, status },
    handleApproveClick,
    handleRefuseClick,
  } = props;

  const color = status === 1 ? "#4caf50" : "#f44336";

  return (
    <ApproveButtonContainer color={color}>
      {status === 1 ? (
        "승인완료"
      ) : status === 2 ? (
        "거절완료"
      ) : (
        <>
          <Button
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
            onClick={(props) => handleApproveClick({ ...props, id })}
            width={80}
          >
            승인
          </Button>
          <Button
            style={{ backgroundColor: "#f44336", color: "#fff" }}
            onClick={(props) => handleRefuseClick({ ...props, id })}
            width={80}
          >
            거절
          </Button>
        </>
      )}
    </ApproveButtonContainer>
  );
};
// Summary 를 위한 totalItem Custom

// 매수 매도
const IsBuyCellContainer = styled.div`
  color: ${(props) => props.color};
`;
export const IsBuyCellRender = (props) => {
  const { value } = props;

  const color = value ? "#ff0000" : "#0000ff";
  return (
    <IsBuyCellContainer color={color}>
      {value === null ? "" : value ? "매수" : "매도"}
    </IsBuyCellContainer>
  );
};

const OrderStatusCellContainer = styled.div`
  color: ${(props) => props.color};
`;

// 상태
export const OrderStatusCellRender = (props) => {
  const { value } = props;
  let status = "",
    color = "#000";

  switch (value) {
    case 0:
      status = "취소";
      color = "#f44336";
      break;
    case 1:
      status = "신규";
      break;
    case 2:
      status = "정정";
      color = "#ff9800";
      break;
    case 3:
      status = "체결";
      color = "#4caf50";
      break;
    case 4:
      status = "체결";
      color = "#4caf50";
      break;
    default:
      status = null;
  }
  return (
    <OrderStatusCellContainer color={color}>{status}</OrderStatusCellContainer>
  );
};

// 청산 버튼
const ButtonContainer = styled.div`
  background: ${(props) => (props.isCancel ? "#f44336" : "#4caf50")};
  color: #fff;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;
export const ConcludeButton = (props) => {
  const { concludeOrder, managerId } = props;

  const handleConcludeOrder = () => {
    const { id, orderNo } = props.data;

    concludeOrder({
      variables: {
        managerId,
        userId: id || props.data.userId,
        orderNo,
        concludePrice: getItemInfo(props.data.itemCode).real_sign.price,
      },
    });
  };

  return (
    <ButtonContainer onClick={() => handleConcludeOrder()} isCancel={false}>
      <div>청산</div>
    </ButtonContainer>
  );
};

export const CancelButton = (props) => {
  const { cancelOrder, managerId } = props;

  const handleCancelOrder = () => {
    const { id, orderNo } = props.data;

    cancelOrder({
      variables: {
        managerId,
        userId: id,
        orderNo,
      },
    });
  };
  return (
    <ButtonContainer onClick={() => handleCancelOrder()} isCancel={true}>
      <div>취소</div>
    </ButtonContainer>
  );
};

export const CustomFormItem = (props) => {
  if (props.prefix && props.suffix)
    return (
      <>
        <span>{prefix}</span>
        <Input value={props.value} />
        <span>{suffix}</span>
      </>
    );
  if (props.prefix)
    return (
      <>
        <span>{prefix}</span>
        <Input value={props.value} />
      </>
    );
  if (props.suffix)
    return (
      <>
        <Input value={props.value} />
        <span>{suffix}</span>
      </>
    );

  return <Input value={props.value} />;
};

const BalanceCellContainer = styled.div`
  text-align: right;
  padding-right: 8px;
`;
export const BalanceCell = ({ value }) => {
  return <BalanceCellContainer>{value.toLocaleString()}</BalanceCellContainer>;
};

// priceCell
export const PriceCell = ({ value, data }) => {
  if (isNaN(value)) return <div>{value}</div>;
  return <div>{parseFloat(value).toFixed(data.digit)}</div>;
};

// nested user id cell
const UserCellContainer = styled.div``;
export const UserIdCell = ({ value }) => {
  const { user } = value;
  return <UserCellContainer>{user.id}</UserCellContainer>;
};

// nested user name cell
export const UserNameCell = ({ value }) => {
  const { user } = value;

  return <UserCellContainer>{user.userName}</UserCellContainer>;
};

export const DateCell = ({ value, type }) => {
  if (value === null) return <></>;
  if (value === "합계") return <span>합계</span>;

  const date = typeof value === "number" ? `${value}` : value;

  const convertedDate =
    type === 0
      ? moment(parseInt(value)).format("YYYY-MM-DD HH:mm:ss")
      : `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;
  return <span>{convertedDate}</span>;
};

export const ValueCellForEditable = ({ item, onChange }) => {
  const [value, setValue] = useState(item.entity[item.dataIndex]);

  const title = value ? (
    <span className="numeric-input-title">
      {value !== "-" ? formatNumber(Number(value)) : "-"}
    </span>
  ) : (
    "값을 입력하세요"
  );
  const handleValueChange = (value) => {
    onChange(item, value);
    setValue(value);
  };
  return (
    <Tooltip title={title} trigger={["focus"]} overlayClassName="numeric-input">
      <Input
        value={value}
        onChange={(e) => {
          handleValueChange(e.target.value);
        }}
      />
    </Tooltip>
  );
};
