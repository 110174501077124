import { useNavigate } from "react-router-dom";
import getMenuItems from "./menuitems";
import { Menu } from "antd";
import { useRealTimeState } from "../reducer/realTimeContext";

const CustomMenu = (props) => {
  const { selectedKeys } = props;
  const { registerUserData, requestBankingData } = useRealTimeState();

  const menuItems = getMenuItems(
    registerUserData.length,
    requestBankingData.length
  );
  const navigate = useNavigate();
  return (
    <Menu
      mode="vertical"
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["sub1"]}
      items={menuItems}
      onClick={(data) => navigate(`/dashboard/${data.key}`, { replace: true })}
      selectedKeys={selectedKeys}
    />
  );
};

export default CustomMenu;
