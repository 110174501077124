import React from "react";
import { Card } from "antd";
import styled from "styled-components";
import { useRealTimeState } from "../reducer/realTimeContext";
import { Link } from "react-router-dom";
const Container = styled.div`
  .ant-card-body {
    padding: 16px;
  }
`;

const Item = ({ title, content, type }) => {
  const linkHref = type === 0 ? "registerStatus" : "depositNWithdrawalStatus";
  return (
    <Card>
      <Link to={linkHref}>
        <div className="itemTitle">{title}</div>
      </Link>
      <div className="itemContent">{content}</div>
    </Card>
  );
};
const RealTimeRequestPresenter = () => {
  const { registerUserData, requestBankingData } = useRealTimeState();

  return (
    <Container>
      {registerUserData.map((data) => (
        <Item
          title="회원가입 승인신청"
          content={`${data.id}님의 회원가입승인 대기중`}
          key={data.id}
          type={0}
        />
      ))}
      {requestBankingData.map((data) => {
        const title =
          data.isDeposit === null
            ? "계좌번호 요청"
            : data.isDeposit
            ? "입금요청"
            : "출금요청";

        return (
          <Item
            key={data.id}
            title={title}
            content={`${data.userId}님의 ${title} 대기중`}
            type={1}
          />
        );
      })}
    </Container>
  );
};

export default React.memo(RealTimeRequestPresenter);
