import { gql } from "@apollo/client";

export const CONCLUDE_ORDER = gql`
  mutation ConcludeOrderByManager(
    $managerId: String!
    $userId: String!
    $orderNo: Int!
    $concludePrice: Float!
  ) {
    concludeOrderByManager(
      managerId: $managerId
      userId: $userId
      orderNo: $orderNo
      concludePrice: $concludePrice
    )
  }
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrderByManager(
    $managerId: String!
    $userId: String!
    $orderNo: Int!
  ) {
    cancelOrderByManager(
      managerId: $managerId
      userId: $userId
      orderNo: $orderNo
    )
  }
`;
