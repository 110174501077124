import { gql } from "@apollo/client";
import { AbuserSettingResult } from "./fragments";
export const GET_ABUSER_SETTING = gql`
  query GetAbuserSetting {
    getAbuserSetting {
      id
      is_notice_available
      abuse_count
    }
  }
`;

export const GET_ABUSER_CONDITION = gql`
  query GetAbuserCondition {
    getAbuserCondition {
      id
      condition_type
      condition_value
      notice_count
    }
  }
`;

export const ABUSER_NOTICE_SETTING = gql`
  mutation AbuserNoticeSetting($id: String, $is_notice_available: Boolean) {
    abuserNoticeSetting(id: $id, is_notice_available: $is_notice_available) {
      id
      is_notice_available
    }
  }
`;

export const ABUSER_SETTING = gql`
  ${AbuserSettingResult}
  mutation AbuserSetting(
    $id: Int
    $condition_type: Int
    $condition_value: Int
    $notice_count: Int
  ) {
    abuserSetting(
      id: $id
      condition_type: $condition_type
      condition_value: $condition_value
      notice_count: $notice_count
    ) {
      ...abuserSettingResults
    }
  }
`;

export const ADD_ABUSE_CONDITION = gql`
  ${AbuserSettingResult}
  mutation AddAbuseCondition(
    $id: Int
    $condition_type: Int
    $condition_value: Int
    $notice_count: Int
  ) {
    addAbuseCondition(
      id: $id
      condition_type: $condition_type
      condition_value: $condition_value
      notice_count: $notice_count
    ) {
      ...abuserSettingResults
    }
  }
`;

export const REMOVE_ABUSE_CONDITION = gql`
  mutation RemoveAbuseCondition($id: Int) {
    removeAbuseCondition(id: $id)
  }
`;

export const SUBSCRIBE_ABUSE = gql`
  subscription SubscribeAbuse {
    subscribeAbuse {
      userId
      count
    }
  }
`;
