import dayjs from "dayjs";
import { Workbook } from "exceljs";

const commonStyle = {
  border: {
    top: { style: "thin", color: { argb: "#000000" } },
    left: { style: "thin", color: { argb: "#000000" } },
    bottom: { style: "thin", color: { argb: "#000000" } },
    right: { style: "thin", color: { argb: "#000000" } },
  },
  fill: {
    type: "pattern",
    fgColor: { argb: "FFFFFF00" },
    bgColor: { argb: "FF0000FF" },
  },
};
const depositNWithdrawalInquiryColumns = [
  { header: "요청시간", key: "createdAt", style: commonStyle },
  { header: "구분", key: "isDeposit", style: commonStyle },
  { header: "상태", key: "status", style: commonStyle },
  { header: "등록번호", key: "id", style: commonStyle },
  { header: "아이디", key: "userId", style: commonStyle },
  { header: "이름", key: "userName", style: commonStyle },
  { header: "유저구분", key: "authority", style: commonStyle },
  { header: "요청금액", key: "request_balance", style: commonStyle },
  { header: "처리시간", key: "endTime", style: commonStyle },
  { header: "처리금액", key: "approved_balance", style: commonStyle },
  { header: "입출금 전 보유금액", key: "before_balance", style: commonStyle },
  { header: "입출금 후 보유금액", key: "after_balance", style: commonStyle },
  { header: "은행", key: "accountBank", style: commonStyle },
  { header: "예금주", key: "accountOwner", style: commonStyle },
  { header: "계좌번호", key: "accountNo", style: commonStyle },
  { header: "추천인", key: "recommendCode", style: commonStyle },
  { header: "비고", key: "memo", style: commonStyle },
];
const depositNWithdrawalColumns = [
  { header: "일자", key: "inquiry_date", style: commonStyle },
  { header: "입금요청건수", key: "deposit_request_count", style: commonStyle },
  { header: "입금승인건수", key: "deposit_approve_count", style: commonStyle },
  { header: "입금거부건수", key: "deposit_refuse_count", style: commonStyle },
  {
    header: "관리자입금건수",
    key: "deposit_manager_count",
    style: commonStyle,
  },
  {
    header: "회원요청입금금액합계",
    key: "deposit_request_sum",
    style: commonStyle,
  },
  {
    header: "입금승인금액합계",
    key: "deposit_approve_sum",
    style: commonStyle,
  },
  { header: "입금거부금액합계", key: "deposit_refuse_sum", style: commonStyle },
  { header: "관리자입금합계", key: "deposit_manager_sum", style: commonStyle },
  { header: "입금처리금액총합", key: "deposit_all_sum", style: commonStyle },
  {
    header: "출금요청건수",
    key: "withdrawal_request_count",
    style: commonStyle,
  },
  {
    header: "출금승인건수",
    key: "withdrawal_approve_count",
    style: commonStyle,
  },
  {
    header: "출금거부건수",
    key: "withdrawal_refuse_count",
    style: commonStyle,
  },
  {
    header: "관리자출금건수",
    key: "withdrawal_manager_count",
    style: commonStyle,
  },
  {
    header: "회원요청출금금액합계",
    key: "withdrawal_request_sum",
    style: commonStyle,
  },
  {
    header: "출금승인금액합계",
    key: "withdrawal_approve_sum",
    style: commonStyle,
  },
  {
    header: "출금거부금액합계",
    key: "withdrawal_refuse_sum",
    style: commonStyle,
  },
  {
    header: "관리자출금합계",
    key: "withdrawal_manager_sum",
    style: commonStyle,
  },
  { header: "출금처리금액총합", key: "withdrawal_all_sum", style: commonStyle },
];

const memberDailyProfitNLossColumns = [
  { header: "날짜", key: "dateId", style: commonStyle, width: 12 },
  {
    header: "주간선물 실현손익",
    key: "domesticProfitNLoss",
    style: commonStyle,
    width: 24,
  },
  {
    header: "주간옵션 실현손익",
    key: "optionProfitNLoss",
    style: commonStyle,
    width: 24,
  },
  {
    header: "야간선물 실현손익",
    key: "night_domestic_profit_loss",
    style: commonStyle,
    width: 24,
  },
  {
    header: "야간옵션 실현손익",
    key: "night_option_profit_loss",
    style: commonStyle,
    width: 24,
  },
  {
    header: "해외선물 실현손익",
    key: "foreignProfitNLoss",
    style: commonStyle,
    width: 24,
  },
  {
    header: "실현손익 수수료",
    key: "pureProfitNLoss",
    style: commonStyle,
    width: 24,
  },
  {
    header: "주간선물 수수료",
    key: "domesticFee",
    style: commonStyle,
    width: 24,
  },
  {
    header: "주간옵션 수수료",
    key: "optionFee",
    style: commonStyle,
    width: 24,
  },
  {
    header: "야간선물 수수료",
    key: "night_domestic_fee",
    style: commonStyle,
    width: 24,
  },
  {
    header: "야간옵션 수수료",
    key: "night_option_fee",
    style: commonStyle,
    width: 24,
  },
  {
    header: "해외선물 수수료",
    key: "foreignFee",
    style: commonStyle,
    width: 24,
  },
  { header: "수수료 합계", key: "totalFee", style: commonStyle, width: 24 },
  { header: "실제손익", key: "profitNLoss", style: commonStyle, width: 24 },
];

const memberListColumns = [
  { header: "아이디", key: "id", style: commonStyle },
  { header: "가입일시", key: "createdAt", style: commonStyle },
  { header: "이름", key: "userName", style: commonStyle },
  { header: "휴대폰", key: "phoneNo", style: commonStyle },
  { header: "이메일", key: "email", style: commonStyle },
  { header: "추천인", key: "recommedCode", style: commonStyle },
  { header: "최종접속일시", key: "lastLogin", style: commonStyle },
  { header: "마지막거래일시", key: "lastTrade", style: commonStyle },
  { header: "접속IP", key: "connectIP", style: commonStyle },
  { header: "접속MAC", key: "connectMAC", style: commonStyle },
  { header: "권한", key: "authority", style: commonStyle },
  { header: "계정상태", key: "userState", style: commonStyle },
  { header: "담보금", key: "userBalance", style: commonStyle },
  { header: "입금은행 아이디", key: "deposit_accountId", style: commonStyle },
  { header: "입금은행", key: "deposit_accountName", style: commonStyle },
  { header: "입금은행명", key: "deposit_accountBank", style: commonStyle },
  {
    header: "입금은행 계좌주",
    key: "deposit_accountOwner",
    style: commonStyle,
  },
  { header: "입금은행 계좌번호", key: "deposit_accountNo", style: commonStyle },
  { header: "출금은행", key: "userAccountBank", style: commonStyle },
  { header: "출금계좌", key: "userAccountNo", style: commonStyle },
  { header: "출금예금주", key: "userAccountOwner", style: commonStyle },
  { header: "국선오버나잇", key: "isOverDomestic", style: commonStyle },
  {
    header: "국선오버나잇 일시",
    key: "updatedData_overDomestic",
    style: commonStyle,
  },
  { header: "옵션오버나잇", key: "isOverOption", style: commonStyle },
  {
    header: "옵션오버나잇 일시",
    key: "updatedData_overOption",
    style: commonStyle,
  },
  { header: "해선오버나잇", key: "isOverForeign", style: commonStyle },
  {
    header: "해선오버나잇 일시",
    key: "updatedData_overForeign",
    style: commonStyle,
  },
  { header: "항셍오버나잇", key: "isOverHangSeng", style: commonStyle },
  {
    header: "항셍오버나잇 일시",
    key: "updatedData_overHangseng",
    style: commonStyle,
  },
  { header: "마이크로", key: "isOverMiniForeign", style: commonStyle },
  {
    header: "마이크로 일시",
    key: "updatedData_miniForeign",
    style: commonStyle,
  },
  { header: "국내선물 수수료", key: "domestic_fee", style: commonStyle },
  { header: "옵션 수수료", key: "option_fee", style: commonStyle },
  { header: "해외선물 수수료", key: "foreign_fee", style: commonStyle },
  { header: "항셍 수수료", key: "hangseng_fee", style: commonStyle },
  {
    header: "국내선물 최대계약수",
    key: "domestic_maxAvailableCount",
    style: commonStyle,
  },
  {
    header: "옵션 최대계약수",
    key: "option_maxAvailableCount",
    style: commonStyle,
  },
  { header: "옵션매도 최대계약수", key: "optionSellCount", style: commonStyle },
  {
    header: "해외선물 최대계약수",
    key: "foreign_maxAvailableCount",
    style: commonStyle,
  },
  {
    header: "항셍 최대계약수",
    key: "hangseng_maxAvailableCount",
    style: commonStyle,
  },
  {
    header: "마이크로해외선물 최대계약수",
    key: "mini_foreign_maxAvailableCount",
    style: commonStyle,
  },
  { header: "메모", key: "memo", style: commonStyle },
];

export default excelExport = async (fileName, data) => {
  const workbook = new Workbook();
  workbook.addWorksheet(fileName);
  const sheet = workbook.getWorksheet(fileName);

  if (fileName === "입출금내역조회") {
    sheet.columns = depositNWithdrawalInquiryColumns;
  }

  if (fileName === "입출금통계") {
    sheet.columns = depositNWithdrawalColumns;
  }

  if (fileName === "회원목록") {
    sheet.columns = memberListColumns;
  }

  if (fileName === "회원일별손익내역") {
    sheet.columns = memberDailyProfitNLossColumns;
  }

  data.forEach((item) => {
    sheet.addRow(item);
  });

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `${dayjs().format("YYYY-MM-DD")} ${fileName}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};
