// 아이디 이름 추천인, 실시간손익, 평가손익, 실현손익, 수수료, 평가담보금
// gubun : 매수/ 매도
// L/S = 매수 / 매도

import { getItemInfo } from "../../cache/itemData";
import { getUserStateData } from "../../cache/userData";
import { memberGradeArr } from "../../common/data";

class Users {
  static currentUser;
  static subscribeCodes;
  static ownPosition;

  constructor() {
    this.users = getUserStateData().map((user) => {
      const {
        id,
        userName,
        recommendCode,
        user_balance_history: userBalanceHistory,
        position,
        authority,
      } = user;

      const { totalFee, pureProfitNLoss, userBalance, profitNLoss } =
        userBalanceHistory[0];
      return {
        id,
        userName,
        recommendCode,
        totalFee,
        position,
        totValuationGNL: 0,
        pureProfitNLoss,
        liveProfitNLoss: 0,
        userBalance,
        liveUserBalance: 0,
        profitNLoss,
        authority,
      };
    });

    this.subscribeCodes = [];
    this.currentUser = null;
    this.ownPosition = [
      {
        gubun: "정회원",
        domestic: {
          buy: 0,
          sell: 0,
        },
        callOption: {
          buy: 0,
          sell: 0,
        },
        putOption: {
          buy: 0,
          sell: 0,
        },
        foreign: {
          buy: 0,
          sell: 0,
        },
      },
      {
        gubun: "테스터",
        domestic: {
          buy: 0,
          sell: 0,
        },
        callOption: {
          buy: 0,
          sell: 0,
        },
        putOption: {
          buy: 0,
          sell: 0,
        },
        foreign: {
          buy: 0,
          sell: 0,
        },
      },
    ];
  }

  initialize() {
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].authority < 3) {
        this.users[i].position = this.calculatePosition(this.users[i]);
        this.users[i] = this.calculateCurrentPosition(this.users[i]);
      }
    }
    return { users: this.users, sum: this.getSum() };
  }

  initializeOwnPosition() {
    for (let i = 0; i < this.ownPosition.length; i++) {
      const keys = Object.keys(this.ownPosition[i]);
      for (let j = 1; j < keys.length; j++) {
        this.ownPosition[i][keys[j]].buy = 0;
        this.ownPosition[i][keys[j]].sell = 0;
      }
    }
    this.calculateOwnPosition();
    const result = [
      ...this.ownPosition.map((pos) => {
        return {
          ...pos,
          domestic: `${pos.domestic.buy} / ${pos.domestic.sell}`,
          callOption: `${pos.callOption.buy} / ${pos.callOption.sell}`,
          putOption: `${pos.putOption.buy} / ${pos.putOption.sell}`,
          foreign: `${pos.foreign.buy} / ${pos.foreign.sell}`,
        };
      }),
    ];

    return [...result, this.getSumOwnPosition()];
  }

  getSumOwnPosition() {
    let sumDomesticBuy = 0;
    let sumCallOptionBuy = 0;
    let sumPutOptionBuy = 0;
    let sumForeignBuy = 0;
    let sumDomesticSell = 0;
    let sumCallOptionSell = 0;
    let sumPutOptionSell = 0;
    let sumForeignSell = 0;

    for (let i = 0; i < this.ownPosition.length; i++) {
      sumDomesticBuy += this.ownPosition[i].domestic.buy;
      sumCallOptionBuy += this.ownPosition[i].callOption.buy;
      sumPutOptionBuy += this.ownPosition[i].putOption.buy;
      sumForeignBuy += this.ownPosition[i].foreign.buy;
      sumDomesticSell += this.ownPosition[i].domestic.sell;
      sumCallOptionSell += this.ownPosition[i].callOption.sell;
      sumPutOptionSell += this.ownPosition[i].putOption.sell;
      sumForeignSell += this.ownPosition[i].foreign.sell;
    }
    return {
      gubun: "합계",
      domestic: `${sumDomesticBuy} / ${sumDomesticSell}`,
      callOption: `${sumCallOptionBuy} / ${sumCallOptionSell}`,
      putOption: `${sumPutOptionBuy} / ${sumPutOptionSell}`,
      foreign: `${sumForeignBuy} / ${sumForeignSell}`,
    };
  }

  calculateCurrentPosition(user) {
    if (user === undefined) return [];
    const { position, userBalance, pureProfitNLoss, totalFee } = user;

    const result = { ...user, pureProfitNLoss, totalFee, userBalance };

    let totValuationGNL = 0;
    for (let i = 0; i < position.length; i++) {
      const { valuationGNL, isSigned } = position[i];
      if (isSigned) {
        totValuationGNL += parseInt(valuationGNL);
      }
    }
    result.liveProfitNLoss = pureProfitNLoss + totalFee + totValuationGNL;
    // 평가 손익
    result.totValuationGNL = totValuationGNL;

    // 실현손익 (pureProfitNLoss + totalFee)
    result.profitNLoss = pureProfitNLoss + totalFee;
    result.liveUserBalance = userBalance + totValuationGNL;

    return result;
  }

  // 보유포지션 계산
  calculateOwnPosition() {
    for (let i = 0; i < this.users.length; i++) {
      const { position, authority } = this.users[i];
      const ownPositionIndex = authority === 1 ? 0 : 1;
      for (let j = 0; j < position.length; j++) {
        const { count, isBuy, isSigned, itemCode } = position[j];
        if (!isSigned) continue;
        // 준회원 , 테스터
        const { type } = getItemInfo(itemCode);

        if (type === 0) {
          if (isBuy) {
            this.ownPosition[ownPositionIndex].domestic.buy += count;
          } else {
            this.ownPosition[ownPositionIndex].domestic.sell += count;
          }
        } else if (type === 1) {
          if (isBuy) {
            this.ownPosition[ownPositionIndex].callOption.buy += count;
          } else {
            this.ownPosition[ownPositionIndex].callOption.sell += count;
          }
        } else if (type === 2) {
          if (isBuy) {
            this.ownPosition[ownPositionIndex].putOption.buy += count;
          } else {
            this.ownPosition[ownPositionIndex].putOption.sell += count;
          }
        } else {
          if (isBuy) {
            this.ownPosition[ownPositionIndex].foreign.buy += count;
          } else {
            this.ownPosition[ownPositionIndex].foreign.sell += count;
          }
        }
      }
    }
    return this.ownPosition;
  }

  // 포지션 계산
  calculatePosition(user) {
    const { id, userName } = user;
    const { position } = user;

    const result = [];

    for (let i = 0; i < position.length; i++) {
      const { itemCode, isBuy, isSigned, price, count } = position[i];

      const {
        short_hname,
        hname,
        unit_price: unitPrice,
        digit,
        tick_value: tickValue,
        real_sign: { price: currentPrice },
        real_hoga: {
          bidho1,
          bidho2,
          bidho3,
          bidho4,
          bidho5,
          offerho1,
          offerho2,
          offerho3,
          offerho4,
          offerho5,
        },
        currency_data: { value },
      } = getItemInfo(itemCode);

      if (!this.subscribeCodes.includes(itemCode)) {
        this.subscribeCodes.push(itemCode);
      }

      if (isSigned) {
        const positionPrice = parseFloat(price);
        const valuationGNL = isBuy
          ? ((currentPrice - positionPrice) * value * tickValue) / unitPrice
          : ((positionPrice - currentPrice) * value * tickValue) / unitPrice;

        let itemName = short_hname || hname;

        const fixedPrice = positionPrice.toFixed(digit + 1).endsWith("0")
          ? positionPrice.toFixed(digit)
          : positionPrice.toFixed(digit + 1);
        const fixedCurrentPrice = currentPrice.toFixed(digit + 1).endsWith("0")
          ? currentPrice.toFixed(digit)
          : currentPrice.toFixed(digit + 1);
        const concludePrice = isBuy
          ? bidho1 || bidho2 || bidho3 || bidho4 || bidho5 || currentPrice
          : offerho1 ||
            offerho2 ||
            offerho3 ||
            offerho4 ||
            offerho5 ||
            currentPrice;
        result.push({
          id,
          userName,
          ...position[i],
          price: fixedPrice,
          currentPrice: fixedCurrentPrice,
          valuationGNL: Math.round(valuationGNL) * count,
          hname: itemName,
          concludePrice,
        });
      } else {
        result.push({ ...position[i], id, userName });
      }
    }
    return result;
  }
  // 보유포지션
  getPositionCount() {
    this.calculateOwnPosition();
    return this.ownPosition;
  }

  getPositionData(id) {
    this.currentUser = id;
    this.calculateCurrentPosition();

    for (let i = 0; i < this.users.length; i++) {
      if (this.currentUser === this.users[i].id) {
        const { position } = this.users[i];

        this.currentSignedPosition = position.filter((data) => data.isSigned);
        this.currentUnsignedPosition = position.filter(
          (data) => !data.isSigned
        );
        break;
      }
    }

    return {
      signedPosition: this.currentSignedPosition,
      unsignedPosition: this.currentUnsignedPosition,
    };
  }

  getPoisitionDataWithSelected(idArr) {
    const signedPosition = [];
    const unsignedPosition = [];
    const targetUsers = this.users.filter((user) => idArr.includes(user.id));
    const totalPositions = targetUsers.map((user) => {
      const { id, userName, position } = user;
      const result = position.map((pos) => {
        return { ...pos, id, userName };
      });
      return result;
    });

    totalPositions.forEach((positions) =>
      positions.forEach((position) => {
        if (position.isSigned) signedPosition.push(position);
        if (!position.isSigned) unsignedPosition.push(position);
      })
    );
    return {
      signedPosition,
      unsignedPosition,
    };
  }

  getUsers() {
    return this.users;
  }

  isExistUser(id) {
    for (let i = 0, count = this.users.length; i < count; i++) {
      if (this.users[i].id === id) {
        return true;
      }
    }
    return false;
  }
  updateUserData(args) {
    const { position, user_balance_history: userBalanceHistory } = args;
    const { userId, pureProfitNLoss, totalFee, userBalance } =
      userBalanceHistory[0];
    for (let i = 0; i < this.users.length; i++) {
      const { id } = this.users[i];

      if (id === userId) {
        this.users[i] = {
          ...this.users[i],
          pureProfitNLoss,
          totalFee,
          userBalance,
          position,
        };

        this.users[i].position = this.calculatePosition(this.users[i]);
        this.users[i] = this.calculateCurrentPosition(this.users[i]);

        return this.users[i];
      }
    }
  }
  addNewUserData(args) {
    const {
      id,
      userName,
      recommendCode,
      user_balance_history: userBalanceHistory,
      position,
      authority,
      userBalance,
      isNew,
    } = args;

    const { totalFee, pureProfitNLoss, profitNLoss } = userBalanceHistory[0];
    if (isNew) return;
    if (!isNew) {
      let newUserData = {
        id,
        userName,
        recommendCode,
        authority: memberGradeArr[authority],
        userBalance,
        totalFee,
        pureProfitNLoss,
        profitNLoss,
        position,
      };
      newUserData.position = this.calculatePosition(newUserData);
      newUserData = this.calculateCurrentPosition(newUserData);
      this.users.push(newUserData);
      return newUserData;
    }
    return;
  }

  getUsersData() {
    return this.users;
  }
  getSum() {
    let sumLiveProfitNLoss = 0;
    let sumTotValuationGNL = 0;
    let sumProfitNLoss = 0;
    let sumTotalFee = 0;
    let sumLiveUserBalance = 0;

    for (let i = 0; i < this.users.length; i++) {
      const {
        liveProfitNLoss,
        liveUserBalance,
        profitNLoss,
        totalFee,
        totValuationGNL,
      } = this.users[i];
      sumLiveProfitNLoss += liveProfitNLoss;
      sumTotValuationGNL += totValuationGNL;
      sumProfitNLoss += profitNLoss;
      sumTotalFee += totalFee;
      sumLiveUserBalance += liveUserBalance;
    }
    return {
      liveProfitNLoss: sumLiveProfitNLoss,
      totValuationGNL: sumTotValuationGNL,
      profitNLoss: sumProfitNLoss,
      totalFee: sumTotalFee,
      liveUserBalance: sumLiveUserBalance,
    };
  }
}

export default Users;
