import { gql } from "@apollo/client";
import { BankingHistoryData } from "./fragments";

export const REQUEST_BANKING_HISTORY = gql`
  ${BankingHistoryData}
  query RequestBankingHistory($skip: Int, $take: Int) {
    requestBankingHistory(skip: $skip, take: $take) {
      requestAccount {
        data {
          ...bankingHistoryData
        }
        totalCount
      }
      deposit {
        data {
          ...bankingHistoryData
        }
        totalCount
      }
      withdrawal {
        data {
          ...bankingHistoryData
        }
        totalCount
      }
    }
  }
`;

export const REQUEST_BANKING_HISTORY_WITH_QUERYTYPE = gql`
  ${BankingHistoryData}
  query RequestBankingHistoryWithQueryType(
    $userId: String
    $skip: Int
    $take: Int
    $queryType: Int!
  ) {
    requestBankingHistoryWithQueryType(
      userId: $userId
      skip: $skip
      take: $take
      queryType: $queryType
    ) {
      data {
        ...bankingHistoryData
      }
      totalCount
    }
  }
`;

export const REQUEST_BANKING_HISTORY_INQUIRY = gql`
  query RequestBankingHistoryInquiry(
    $skip: Int
    $take: Int
    $inquiryStart: String
    $inquiryEnd: String
    $inquiryType: Int
    $inquiryValue: String
  ) {
    requestBankingHistoryInquiry(
      skip: $skip
      take: $take
      inquiryStart: $inquiryStart
      inquiryEnd: $inquiryEnd
      inquiryType: $inquiryType
      inquiryValue: $inquiryValue
    ) {
      data {
        id
        status
        isDeposit
        request_balance
        before_balance
        after_balance
        accountBank
        accountNo
        accountOwner
        memo
        createdAt
        lastUpdated
        user {
          id
          userName
          recommendCode
          authority
        }
      }
      totalCount
    }
  }
`;

export const REQUEST_BANKING_HISTORY_INQUIRY_TOTAL = gql`
  query RequestBankingHistoryInquiryTotal(
    $inquiryStart: String
    $inquiryEnd: String
    $inquiryType: Int
    $inquiryValue: String
  ) {
    requestBankingHistoryInquiryTotal(
      inquiryStart: $inquiryStart
      inquiryEnd: $inquiryEnd
      inquiryType: $inquiryType
      inquiryValue: $inquiryValue
    ) {
      id
      status
      isDeposit
      request_balance
      before_balance
      after_balance
      accountBank
      accountNo
      accountOwner
      memo
      createdAt
      lastUpdated
      user {
        id
        userName
        recommendCode
        authority
      }
    }
  }
`;

export const SUBSCRIBE_REQUEST_BANKING = gql`
  subscription SubscribeRequestBanking {
    subscribeRequestBanking {
      id
      userId
      isDeposit
      request_balance
    }
  }
`;

export const APPROVE_BANKING = gql`
  mutation ApproveBanking(
    $id: Int!
    $isApproved: Boolean!
    $accountOwner: String
    $accountNo: String
    $accountBank: Int
  ) {
    approveBanking(
      id: $id
      isApproved: $isApproved
      accountOwner: $accountOwner
      accountNo: $accountNo
      accountBank: $accountBank
    ) {
      id
      status
      isDeposit
      request_balance
      before_balance
      after_balance
      accountBank
      accountNo
      accountOwner
      memo
      createdAt
      lastUpdated
      user {
        id
        userName
        recommendCode
        authority
      }
    }
  }
`;

export const REQUEST_BANKING_STATISITCS = gql`
  query RequestBankingStatisics(
    $skip: Int
    $take: Int
    $inquiryStart: String
    $inquiryEnd: String
  ) {
    requestBankingStatisics(
      skip: $skip
      take: $take
      inquiryStart: $inquiryStart
      inquiryEnd: $inquiryEnd
    ) {
      data {
        inquiry_date
        deposit_request_count
        deposit_approve_count
        deposit_refuse_count
        deposit_manager_count
        deposit_request_sum
        deposit_approve_sum
        deposit_refuse_sum
        deposit_manager_sum
        deposit_all_sum
        withdrawal_request_count
        withdrawal_approve_count
        withdrawal_refuse_count
        withdrawal_manager_count
        withdrawal_request_sum
        withdrawal_approve_sum
        withdrawal_refuse_sum
        withdrawal_manager_sum
        withdrawal_all_sum
      }
      totalCount
      sum {
        inquiry_date
        deposit_request_count
        deposit_approve_count
        deposit_refuse_count
        deposit_manager_count
        deposit_request_sum
        deposit_approve_sum
        deposit_refuse_sum
        deposit_manager_sum
        deposit_all_sum
        withdrawal_request_count
        withdrawal_approve_count
        withdrawal_refuse_count
        withdrawal_manager_count
        withdrawal_request_sum
        withdrawal_approve_sum
        withdrawal_refuse_sum
        withdrawal_manager_sum
        withdrawal_all_sum
      }
    }
  }
`;

export const REQUEST_BANKING_STATISITCS_TOTAL = gql`
  query RequestBankingStatisicsTotal(
    $inquiryStart: String
    $inquiryEnd: String
  ) {
    requestBankingStatisicsTotal(
      inquiryStart: $inquiryStart
      inquiryEnd: $inquiryEnd
    ) {
      data {
        inquiry_date
        deposit_request_count
        deposit_approve_count
        deposit_refuse_count
        deposit_manager_count
        deposit_request_sum
        deposit_approve_sum
        deposit_refuse_sum
        deposit_manager_sum
        deposit_all_sum
        withdrawal_request_count
        withdrawal_approve_count
        withdrawal_refuse_count
        withdrawal_manager_count
        withdrawal_request_sum
        withdrawal_approve_sum
        withdrawal_refuse_sum
        withdrawal_manager_sum
        withdrawal_all_sum
      }
      sum {
        inquiry_date
        deposit_request_count
        deposit_approve_count
        deposit_refuse_count
        deposit_manager_count
        deposit_request_sum
        deposit_approve_sum
        deposit_refuse_sum
        deposit_manager_sum
        deposit_all_sum
        withdrawal_request_count
        withdrawal_approve_count
        withdrawal_refuse_count
        withdrawal_manager_count
        withdrawal_request_sum
        withdrawal_approve_sum
        withdrawal_refuse_sum
        withdrawal_manager_sum
        withdrawal_all_sum
      }
    }
  }
`;
