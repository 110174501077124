import React, { useRef, useEffect } from "react";

import styled from "styled-components";
import RealTimeRequestPresenter from "./RealTimeRequestPresenter";
import { useQuery, useSubscription } from "@apollo/client";
import {
  INITIALIZE_REALTIME_REQUEST,
  REQUEST_REGISTER,
} from "../../graphql/monitor";
import {
  useRealTimeDispatch,
  useRealTimeState,
} from "../reducer/realTimeContext";

import { SUBSCRIBE_REQUEST_BANKING } from "../../graphql/banking";
import { SUBSCRIBE_ABUSE } from "../../graphql/abuse";
import notification from "../../common/notification";

const Container = styled.div`
  min-width: 240px;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    font-weight: 700;
    font-size: 14px;
    border-bottom: 1px solid #ccc;
  }
  .contentContainer {
    padding: 8px;
    background-color: #eaeff1;
    height: calc(100% - 64px);
  }
`;

const openChildWindow = (type) => {
  var childWindow = window.open(
    "http://" + window.location.host + `/#/popup?type=${type}`,
    "_blank",
    "width= 270, height=95, top= 300, left= 300"
  );
  childWindow.addEventListener("beforeunload", function (event) {
    console.log("popup 닫기");
  });
};
const RealTimeRequestContainer = () => {
  const { registerUserData, requestBankingData } = useRealTimeState();

  const dispatch = useRealTimeDispatch();
  const audioPlayer = useRef(new Audio());

  useQuery(INITIALIZE_REALTIME_REQUEST, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ initializeRealTimeRequest }) => {
      dispatch({
        type: "INITIALIZE_REALTIME_REQUEST",
        data: initializeRealTimeRequest,
      });
    },
    onError: (error) => console.log("monitorData initializing error", error),
  });

  // 입출금 요청
  useSubscription(SUBSCRIBE_REQUEST_BANKING, {
    onData: ({ data: { data } }) => {
      const { subscribeRequestBanking } = data;

      if (requestBankingData.length < subscribeRequestBanking.length) {
        subscribeRequestBanking[subscribeRequestBanking.length - 1]
          .isDeposit === null
          ? openChildWindow("bankingaAccount")
          : openChildWindow("banking");

        audioPlayer.current.pause();
        audioPlayer.current.src = new URL(
          "../../../../audio/VTSM_BANKINGIO.WAV",
          import.meta.url
        );
        audioPlayer.current.load();
        audioPlayer.current.play();
      }

      dispatch({ type: "REQUEST_BANKING", data: subscribeRequestBanking });
    },
    onError: (error) => console.log("SUBSCRIBE_REQUEST_BANKING error", error),
  });

  // 회원가입 요청
  useSubscription(REQUEST_REGISTER, {
    onData: ({ data: { data } }) => {
      const { requestRegister: subscribeRequestRegister } = data;
      if (registerUserData.length < subscribeRequestRegister.length) {
        openChildWindow("register");
        audioPlayer.current.pause();
        audioPlayer.current.src = new URL(
          "../../../../audio/VTSM_NEWMEMBER.WAV",
          import.meta.url
        );
        audioPlayer.current.load();
        audioPlayer.current.play();
      }

      dispatch({ type: "REQUEST_REGISTER", data: subscribeRequestRegister });
    },
  });

  useSubscription(SUBSCRIBE_ABUSE, {
    onData: ({ data: { data } }) => {
      const { count, userId } = data.subscribeAbuse;
      notification({
        title: "HERMES",
        content: `${userId}의 ${count}번의 이상주문을 감지했습니다`,
      });
    },
  });

  return (
    <Container>
      {/* <Modal /> */}
      <div className="title">
        <div>실시간 요청</div>
      </div>
      <div className="contentContainer">
        <RealTimeRequestPresenter />
      </div>
    </Container>
  );
};

export default RealTimeRequestContainer;
