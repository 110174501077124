import { gql } from "@apollo/client";

const NoticeData = gql`
  fragment noticeData on NoticeData {
    id
    createdAt
    title
    content
    isBroadcast
    userId
    clientId
    mustRead
    isPopup
  }
`;

export const INQUIRY_NOTICE = gql`
  ${NoticeData}
  query InquiryNotice(
    $skip: Int
    $take: Int
    $isBroadcast: Boolean
    $clientId: String
  ) {
    inquiryNotice(
      skip: $skip
      take: $take
      isBroadcast: $isBroadcast
      clientId: $clientId
    ) {
      data {
        ...noticeData
      }
      totalCount
    }
  }
`;

export const ADD_NOTICE = gql`
  ${NoticeData}
  mutation AddNotice(
    $createdAt: DateTime
    $title: String
    $content: String
    $isBroadcast: Boolean
    $userId: String
    $clientId: String
    $mustRead: Boolean
  ) {
    addNotice(
      createdAt: $createdAt
      title: $title
      content: $content
      isBroadcast: $isBroadcast
      userId: $userId
      clientId: $clientId
      mustRead: $mustRead
    ) {
      data {
        ...noticeData
      }
      code
    }
  }
`;

export const REVISE_NOTICE = gql`
  ${NoticeData}
  mutation ReviseNotice(
    $id: Int
    $createdAt: DateTime
    $title: String
    $content: String
    $mustRead: Boolean
    $isPopup: Boolean
  ) {
    reviseNotice(
      id: $id
      createdAt: $createdAt
      title: $title
      content: $content
      mustRead: $mustRead
      isPopup: $isPopup
    ) {
      data {
        ...noticeData
      }
      code
    }
  }
`;

export const DELETE_NOTICE = gql`
  mutation DeleteNotice($id: Int) {
    deleteNotice(id: $id) {
      data {
        id
      }
      code
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($users: String, $title: String, $content: String) {
    sendMessage(users: $users, title: $title, content: $content)
  }
`;

export const FETCH_DEPOSITNOTICE = gql`
  query FetchDepositNotice {
    fetchDepositNotice {
      content
    }
  }
`;

export const HANDLE_DEPOSITNOTICE = gql`
  mutation HandleDepositNotice($content: String) {
    handleDepositNotice(content: $content) {
      content
    }
  }
`;
