import React, { forwardRef } from "react";
import { ProTable, EditableProTable } from "@ant-design/pro-components";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Table,
} from "antd";

import dayjs from "dayjs";
import { getQueryString } from "./data";

export const CustomBasicTable = forwardRef((props, ref) => {
  return (
    <Table ref={ref} bordered={true} size="small" sticky={true} {...props} />
  );
});

export const CustomProTable = forwardRef((props, ref) => {
  return (
    <ProTable
      ref={ref}
      search={{ labelWidth: "auto" }}
      type="table"
      size="small"
      options={{
        setting: false,
      }}
      cardBordered={{
        search: false,
        table: true,
      }}
      {...props}
    />
  );
});

export const CustomEditableTable = forwardRef((props, ref) => {
  return (
    <EditableProTable
      ref={ref}
      cardBordered={{
        search: false,
        table: true,
      }}
      {...props}
    />
  );
});

export const CommonCondition = React.memo((props) => {
  const {
    type,
    handleSubmit,
    isOrderSignHistory,
    isDaySearch,
    defaultValue,
    isForWeek,
  } = props;

  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const inquiryType = [
    { value: 0, label: "아이디" },
    { value: 1, label: "이름" },
    { value: 2, label: "핸드폰" },
    { value: 3, label: "이메일" },
    { value: 4, label: "추천인" },
    { value: 5, label: "아이피" },
    { value: 6, label: "입금/출금" },
  ];

  const dateFormat = "YYYY-MM-DD";

  const initialDate = () => {
    const today =
      dayjs() < dayjs().set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
        ? dayjs()
            .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
            .subtract(1, "days")
        : dayjs().set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
    if (isForWeek && isDaySearch)
      return dayjs(today.subtract(7, "days"), dateFormat);
    let value = dayjs(today, dateFormat);

    if (!isDaySearch)
      value = isForWeek
        ? [
            dayjs(today.subtract(7, "day"), dateFormat),
            dayjs(today, dateFormat),
          ]
        : [
            dayjs(today.subtract(1, "day"), dateFormat),
            dayjs(today, dateFormat),
          ];
    return value;
  };

  return (
    <Form
      name="condition"
      form={form}
      initialValues={{
        inquiryType: inquiryType[0].value,
        inquiryValue: defaultValue,
        date: initialDate(),
      }}
      onFinish={handleSubmit}
    >
      <Space align="center">
        {type !== 0 && (
          <>
            <Form.Item name="inquiryType">
              <Select
                options={inquiryType.slice(0, type)}
                popupMatchSelectWidth={120}
              />
            </Form.Item>
            <Form.Item name="inquiryValue">
              <Input />
            </Form.Item>
          </>
        )}
        <Form.Item name="date" label="조회기간">
          {isDaySearch ? (
            <DatePicker size="middle" />
          ) : (
            <RangePicker size="middle" />
          )}
        </Form.Item>
        {isDaySearch && <Form.Item>~ 익일 8:00</Form.Item>}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            조회
          </Button>
        </Form.Item>
        {isOrderSignHistory && (
          <Form.Item name="isOnlySigned" valuePropName="checked">
            <Checkbox>체결주문만</Checkbox>
          </Form.Item>
        )}
      </Space>
    </Form>
  );
});

export const ExcelExportButton = (props) => {
  const { handleExporting } = props;
  return (
    <Button type="primary" onClick={() => handleExporting()}>
      엑셀
    </Button>
  );
};
