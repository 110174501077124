import React from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import CustomEditor from "../Notice/CustomEditor";
import { Button, Form, Card } from "antd";

import {
  FETCH_DEPOSITNOTICE,
  HANDLE_DEPOSITNOTICE,
} from "../../../graphql/notice";

const Container = styled.div``;

const DepositNoticePresenter = () => {
  const [form] = Form.useForm();

  const { loading, error, data } = useQuery(FETCH_DEPOSITNOTICE);
  const [handleDepositNotice] = useMutation(HANDLE_DEPOSITNOTICE, {
    refetchQueries: [{ query: FETCH_DEPOSITNOTICE }],
  });

  const handleOnFinish = () => {
    handleDepositNotice({
      variables: {
        content: form.getFieldValue("content"),
      },
    });
  };

  if (error) return <div>error..{error}</div>;
  if (loading) return <div>loading...</div>;

  return (
    <Container>
      <Card>
        <Form
          form={form}
          initialValues={{ content: data.fetchDepositNotice.content }}
          onFinish={handleOnFinish}
        >
          <Form.Item name="content">
            <CustomEditor />
          </Form.Item>

          <Button type="primary" block htmlType="submit">
            등록
          </Button>
        </Form>
      </Card>
    </Container>
  );
};

export default DepositNoticePresenter;
