import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Layout, Menu, ConfigProvider, theme, Typography, Spin } from "antd";
import koKR from "antd/lib/locale/ko_KR";
const { useToken } = theme;
const { Content, Sider } = Layout;
import { RealTimeProvider } from "./reducer/realTimeContext";
import RealTimeRequest from "./RealTimeRequest";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUserDispatch, useUserState } from "../../context";
import {
  GET_All_USER_DATA,
  GET_RECOMMENDER_INFO,
  GET_SERVER_LIST,
  SUBSCRIBE_CURRENTSTATE,
  SUBSCRIBE_USER_SETTING,
} from "../graphql/manageUser";
import { handleUserCurrentState, writeUserData } from "../cache/userData";
import { mainClient } from "../../apollo";
import {
  GET_ALL_ITEMINFO,
  GET_OPTION_INFO,
  SUBSCRIBE_REAL_HOGA,
  SUBSCRIBE_REAL_SIGN,
} from "../graphql/itemInfo";
import {
  saveForeignItem,
  saveHogaData,
  saveItemData,
  saveSignData,
} from "../cache/itemData";
import { INQUIRY_DEPOSIT_ACCOUNT } from "../graphql/depositAccount";
import { useMediaQuery } from "react-responsive";

import getMenuItems from "./Navigator/menuitems";
import CustomMenu from "./Navigator/CustomMenu";

const DashBoard = () => {
  const [selectedKeys, setSelectedKeys] = useState("");
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { user } = useUserState();
  const dispatch = useUserDispatch();
  const { token } = useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: 1980 });
  const menuItems = getMenuItems(2, 1);

  const responsiveToken = {
    ...token,
    fontSize: isSmallScreen ? 12 : 14,
    cellPaddingBlockSM: 4,
    cellPaddingInlineSM: 4,
    padding: 4,
    paddingLG: 8,
  };

  // 유저데이터
  const { data: userData } = useQuery(GET_All_USER_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      isAll: true,
      withTester: true,
    },
  });

  // 아이템 데이터
  const { data: allItemInfoData } = useQuery(GET_ALL_ITEMINFO);

  const { data: optionData } = useQuery(GET_OPTION_INFO);

  useEffect(() => {
    if (user.userId === null) {
      const userId = window.sessionStorage.getItem("user");
      if (userId === null) {
        navigate("/", { replace: true });
      } else {
        dispatch({ type: "LOGIN", data: { userId } });
      }
    }
    setSelectedKeys(location.pathname.replace("/dashboard/", ""));
  }, [location]);

  // userData 초기화
  useEffect(() => {
    if (userData && allItemInfoData && optionData && loading) {
      const fetchDataFinish = writeUserData(userData.getAllUserData);

      const { getAllItemInfo } = allItemInfoData;
      const {
        getOptionInfo: { callOption, putOption },
      } = optionData;
      [...getAllItemInfo, ...callOption, ...putOption].map((item) => {
        saveItemData(item);
      });
      saveForeignItem(getAllItemInfo.filter((item) => item.type !== 0));
      if (fetchDataFinish) setLoading(false);
    }
  }, [userData, loading, allItemInfoData, optionData]);

  useEffect(() => {
    // 추천인 저장
    mainClient.query({
      query: GET_RECOMMENDER_INFO,
      fetchPolicy: "cache-first",
    });

    // 입금은행
    mainClient.query({
      fetchPolicy: "cache-first",
      query: INQUIRY_DEPOSIT_ACCOUNT,
    });

    // 서버 저장
    mainClient.query({
      query: GET_SERVER_LIST,
      fetchPolicy: "cache-first",
    });
  }, []);

  // currentState subscribe
  useEffect(() => {
    const subscribeCurrentState = mainClient
      .subscribe({
        query: SUBSCRIBE_CURRENTSTATE,
        fetchPolicy: "no-cache",
      })
      .subscribe(
        ({ data: { subscribeCurrentState: subscribeCurrentStateData } }) => {
          handleUserCurrentState(subscribeCurrentStateData);
        }
      );

    return () => {
      subscribeCurrentState.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscribeSign = mainClient
      .subscribe({
        query: SUBSCRIBE_REAL_SIGN,
        fetchPolicy: "no-cache",
      })
      .subscribe(({ data: { subscribeRealSign: sign } }) => {
        saveSignData(sign);
      });
    const subscribeHoga = mainClient
      .subscribe({
        query: SUBSCRIBE_REAL_HOGA,
        fetchPolicy: "no-cache",
      })
      .subscribe(({ data: { subscribeReal: data } }) => {
        saveHogaData(data);
      });

    return () => {
      subscribeSign.unsubscribe();
      subscribeHoga.unsubscribe();
    };
  }, []);

  // userSetting
  useEffect(() => {
    const subscribeUserSetting = mainClient
      .subscribe({
        query: SUBSCRIBE_USER_SETTING,
      })
      .subscribe(
        ({
          data: { subscribeUserSetting: subscribeUserSettingData },
          errors,
        }) => {
          if (errors) console.log("subscribeUserSetting", errors);
          console.log(subscribeUserSettingData, "subscribeUserSettingData");
          handleUserCurrentState(subscribeUserSettingData);
        }
      );
    return () => subscribeUserSetting.unsubscribe();
  }, []);

  return (
    <ConfigProvider
      locale={koKR}
      theme={{
        token: responsiveToken,
      }}
    >
      <RealTimeProvider>
        <Layout style={{ background: "none", height: "100%" }}>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            style={{
              background: "none",
              height: "100vh",
              borderRight: "1px solid lightgrey",
              overflow: "auto",
            }}
            width={240}
            trigger={null}
          >
            <div
              style={{
                borderBottom: "1px solid lightgrey",
                padding: "8px 16px",
                marginBottom: "8px",
              }}
            >
              <Typography.Title level={3} style={{ margin: 0 }}>
                HERMES
              </Typography.Title>
            </div>
            <CustomMenu />
            {/* <Menu
              mode="vertical"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              items={menuItems}
              onClick={(data) =>
                navigate(`/dashboard/${data.key}`, { replace: true })
              }
              selectedKeys={selectedKeys}
            /> */}
          </Sider>
          <Layout
            style={{ minWidth: 1280, overflow: "hidden", overflowY: "auto" }}
          >
            <Content style={{ padding: "16px" }}>
              {loading ? <Spin size="large" /> : <Outlet />}
            </Content>
          </Layout>
          <Sider
            style={{ background: "none", borderLeft: "1px solid lightgrey" }}
            width={248}
          >
            <RealTimeRequest />
          </Sider>
        </Layout>
      </RealTimeProvider>
    </ConfigProvider>
  );
};

export default DashBoard;
