import { gql } from "@apollo/client";

const DepositAccount = gql`
  fragment depositAccount on DepositAccount {
    id
    accountName
    bank
    accountNo
    accountOwner
    userCount
    lastUpdated
  }
`;

export const INQUIRY_DEPOSIT_ACCOUNT = gql`
  ${DepositAccount}
  query InquiryDepositAccount {
    inquiryDepositAccount {
      ...depositAccount
    }
  }
`;

export const REGISTER_DEPOSIT_ACCOUNT = gql`
  ${DepositAccount}
  mutation RegisterDepositAccount(
    $bank: Int
    $accountName: String
    $accountNo: String
    $accountOwner: String
  ) {
    registerDepositAccount(
      accountName: $accountName
      bank: $bank
      accountNo: $accountNo
      accountOwner: $accountOwner
    ) {
      data {
        ...depositAccount
      }
      code
    }
  }
`;
export const REVISE_DEPOSIT_ACCOUNT = gql`
  ${DepositAccount}
  mutation ReviseDepositAccount(
    $id: Int
    $accountName: String
    $bank: Int
    $accountNo: String
    $accountOwner: String
  ) {
    reviseDepositAccount(
      id: $id
      accountName: $accountName
      bank: $bank
      accountNo: $accountNo
      accountOwner: $accountOwner
    ) {
      data {
        ...depositAccount
      }
      code
    }
  }
`;
export const DELETE_DEPOSIT_ACCOUNT = gql`
  mutation DeleteDepositAccount($id: Int) {
    deleteDepositAccount(id: $id)
  }
`;
