import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./components/login";

import OrderStatus from "./components/main/Content/OrderStatus";
import MemberList from "./components/main/Content/MemberList";
import DashBoard from "./components/main/DashBoard";

// import to lazy
const RegisterStatus = lazy(() =>
  import("./components/main/Content/RegisterStatus")
);
// profitNLoss
import ProfitStatus from "./components/main/Content/ProfitNLoss/ContentContainer";

// const AddMember = lazy(() => import("./components/main/Content/AddMember"));
const MemberOrder = lazy(() => import("./components/main/Content/MemberOrder"));
const DepositNWithdrawalStatus = lazy(() =>
  import(
    "./components/main/Content/DepositNWithdrawalStatus/DepositNWithdrawalStatusContainer"
  )
);
const CheckAbuse = lazy(() =>
  import("./components/main/Content/CheckAbuse/CheckAbuseContainer")
);
const DepositNWithdrawalInquiry = lazy(() =>
  import("./components/main/Content/DepositNWithdrawalInquiry")
);
const DepositNWithdrawal = lazy(() =>
  import(
    "./components/main/Content/DepositNWithdrawal/DepositNWithdrawalContainer"
  )
);
const TotalProfitNLoss = lazy(() =>
  import("./components/main/Content/TotalProfitNLoss")
);
const RecommendedProfitNLoss = lazy(() =>
  import(
    "./components/main/Content/RecommendedProfitNLoss/RecommendedProfitNLossContainer"
  )
);
const MemberTotalProfitNLoss = lazy(() =>
  import(
    "./components/main/Content/MemberTotalProfitNLoss/MemberTotalProfitNLossContainer"
  )
);
const OrderSignedHistory = lazy(() =>
  import("./components/main/Content/OrderSignedHistory")
);
const MemberOrderHistory = lazy(() =>
  import("./components/main/Content/MemberOrderHistory")
);
const MemberAccountBalanceReviseHistory = lazy(() =>
  import("./components/main/Content/MemberAccountBalanceReviseHistory")
);
const LiveTimeLoginHistory = lazy(() =>
  import("./components/main/Content/LiveTimeLoginHistory")
);
const LoginHistoryInquiry = lazy(() =>
  import("./components/main/Content/LoginHistoryInquiry")
);
const ManageBlackList = lazy(() =>
  import("./components/main/Content/ManageBlackList")
);
const BlackListInquiry = lazy(() =>
  import("./components/main/Content/BlackListInquiry")
);
const Notice = lazy(() => import("./components/main/Content/Notice"));
const MarketSchedule = lazy(() =>
  import("./components/main/Content/MarketSchedule")
);
const ManagerLoginHistory = lazy(() =>
  import("./components/main/Content/ManagerLoginHistory")
);
const ManagerWorkHistory = lazy(() =>
  import("./components/main/Content/ManagerWorkHistory")
);
const ManageDepositAccount = lazy(() =>
  import("./components/main/Content/ManageDepositAccount")
);
const ManageDomestic = lazy(() =>
  import("./components/main/Content/ManageDomestic")
);
const ManageForeign = lazy(() =>
  import("./components/main/Content/ManageForeign")
);
const ManageDomesticTradingTime = lazy(() =>
  import("./components/main/Content/ManageDomesticTradingTime")
);
const ManageForeignTradingTime = lazy(() =>
  import("./components/main/Content/ManageForeignTradingTime")
);
const MemberDailyProfitNLoss = lazy(() =>
  import("./components/main/Content/MemberDailyProfitNLoss")
);
const ChangePassword = lazy(() =>
  import("./components/main/Content/ChangePassword")
);
const ManageRecommender = lazy(() =>
  import(
    "./components/main/Content/ManageRecommender/ManageRecommenderContainer"
  )
);
const Popup = lazy(() => import("./components/main/Popup"));
const OrderStatusByItem = lazy(() =>
  import("./components/main/Content/OrderStatusByItem")
);

// import MemberAccountBalanceReviseHistory from "./components/main/Content/MemberAccountBalanceReviseHistory";
// import LiveTimeLoginHistory from "./components/main/Content/LiveTimeLoginHistory";
// import LoginHistoryInquiry from "./components/main/Content/LoginHistoryInquiry";
// import ManageBlackList from "./components/main/Content/ManageBlackList";
// import BlackListInquiry from "./components/main/Content/BlackListInquiry";
// import Notice from "./components/main/Content/Notice";
// import MarketSchedule from "./components/main/Content/MarketSchedule";
// import ManagerLoginHistory from "./components/main/Content/ManagerLoginHistory";
// import ManagerWorkHistory from "./components/main/Content/ManagerWorkHistory";
// import ManageDepositAccount from "./components/main/Content/ManageDepositAccount";
// import ManageDomestic from "./components/main/Content/ManageDomestic";
// import ManageForeign from "./components/main/Content/ManageForeign";
// import ManageDomesticTradingTime from "./components/main/Content/ManageDomesticTradingTime";
// import ManageForeignTradingTime from "./components/main/Content/ManageForeignTradingTime";
// import MemberDailyProfitNLoss from "./components/main/Content/MemberDailyProfitNLoss";
// import ChangePassword from "./components/main/Content/ChangePassword";
// import ManageRecommender from "./components/main/Content/ManageRecommender";
// import Popup from "./components/main/Popup";
import { UserProvider } from "./context";
import { Spin } from "antd";
import styled from "styled-components";
import DepositNotice from "./components/main/Content/DepositNotice/DepositNoticeContainer";

const SpinContainer = styled.div`
  text-align: center;
  vertical-align: middle;
`;
const CustomRouter = () => {
  return (
    <UserProvider>
      <Suspense
        fallback={
          <SpinContainer>
            <Spin size="large" />
          </SpinContainer>
        }
      >
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/popup" element={<Popup />} />
          <Route path="/dashboard" exact element={<DashBoard />}>
            {/* 현황판 */}
            <Route path="main" element={<ProfitStatus />} />
            {/* 주문별 현황판 */}
            <Route path="orderStatusByItem" element={<OrderStatusByItem />} />
            {/* 회원별 주문현황 */}
            <Route path="orderStatus" element={<OrderStatus />} />
            {/* 회원목록 */}
            <Route path="memberList" element={<MemberList />} />
            {/* 회원가입신청현황 */}
            <Route path="registerStatus" element={<RegisterStatus />} />
            {/* 신규회원등록 */}
            {/* <Route path="addMember" element={<AddMember />} /> */}
            {/* 회원주문및신호관리 */}
            <Route path="memberOrder" element={<MemberOrder />} />

            {/* 입출금신청현황 */}
            <Route
              path="depositNWithdrawalStatus"
              element={<DepositNWithdrawalStatus />}
            />
            {/* 입출금내역조회 */}
            <Route
              path="depositNWithdrawalInquiry"
              element={<DepositNWithdrawalInquiry />}
            />
            {/* 입출금통계 */}
            <Route path="depositNWithdrawal" element={<DepositNWithdrawal />} />
            {/* 입금전 공지사항 작성공간 */}
            <Route path="depositNotice" element={<DepositNotice />} />
            {/* 전체손익내역 */}
            <Route path="totalProfitNLoss" element={<TotalProfitNLoss />} />
            {/* 추천인별 손익내역 */}
            <Route
              path="recommendedProfitNLoss"
              element={<RecommendedProfitNLoss />}
            />
            {/* 회원 총손익 집계내역 */}
            <Route
              path="memberTotalProfitNLoss"
              element={<MemberTotalProfitNLoss />}
            />
            {/* 회원 일별손익내역 */}
            <Route
              path="memberDailyProfitNLoss"
              element={<MemberDailyProfitNLoss />}
            />
            {/* 주문체결내역 */}
            <Route path="orderSignedHistory" element={<OrderSignedHistory />} />
            {/* 회원별 거래내역 */}
            <Route path="memberOrderHistory" element={<MemberOrderHistory />} />

            {/* 회원담보금보정내역 */}
            <Route
              path="memberAccountBalanceReviseHistory"
              element={<MemberAccountBalanceReviseHistory />}
            />
            {/* 실시간 접속내역 */}
            <Route
              path="liveTimeLoginHistory"
              element={<LiveTimeLoginHistory />}
            />
            {/* 회원접속이력조회 */}
            <Route
              path="loginHisotryInquiry"
              element={<LoginHistoryInquiry />}
            />
            {/* 거래오용 감시 */}
            <Route path="checkAbuse" element={<CheckAbuse />} />
            {/* 블랙리스트 관리 */}
            <Route path="manageBlackList" element={<ManageBlackList />} />

            {/* 블랙리스트 대상자조회 */}
            <Route path="blackListInquiry" element={<BlackListInquiry />} />
            {/* 공지사항 */}
            <Route path="notice" element={<Notice />} />
            {/* 장운영일정 */}
            <Route path="marketSchedule" element={<MarketSchedule />} />
            {/* 관리자 접속이력조회 */}
            <Route
              path="managerLoginHistory"
              element={<ManagerLoginHistory />}
            />
            {/* 관리자 작업 이력조회 */}
            <Route path="managerWorkHistory" element={<ManagerWorkHistory />} />
            {/* 입금계좌관리*/}
            <Route
              path="manageDepositAccount"
              element={<ManageDepositAccount />}
            />
            {/* 거래종목관리(국내) */}
            <Route path="manageDomestic" element={<ManageDomestic />} />
            {/* 거래종목관리(해외) */}
            <Route path="manageForeign" element={<ManageForeign />} />
            {/* 거래시간관리(국내) */}
            <Route
              path="manageDomesticTradingTime"
              element={<ManageDomesticTradingTime />}
            />
            {/* 거래시간관리(해외) */}
            <Route
              path="manageForeignTradingTime"
              element={<ManageForeignTradingTime />}
            />
            {/*
             비밀번호변경 */}
            <Route path="changePassword" element={<ChangePassword />} />
            {/* 비밀번호변경 */}
            <Route path="manageRecommender" element={<ManageRecommender />} />
          </Route>
        </Routes>
      </Suspense>
    </UserProvider>
  );
};

export default CustomRouter;
