import React from "react";
import styled from "styled-components";
import DepositNoticePresenter from "./DepositNoticePresenter";
const Container = styled.div``;

const DepositNoticeContainer = () => {
  return (
    <Container>
      <DepositNoticePresenter />
    </Container>
  );
};

export default DepositNoticeContainer;
