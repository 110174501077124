const { mainClient } = require("../../apollo");

import {
  HOGA_DATA,
  ITEM_DATA,
  SIGN_DATA,
  FOREIGN_DATA,
  FOREIGN_CODES,
} from "./itemDataGql";

export const saveItemData = (data) => {
  mainClient.writeFragment({
    id: `ITEM_DATA:${data.code}`,
    fragment: ITEM_DATA,
    data: {
      ...data,
      __typename: "ItemData",
    },
    overwrite: true,
  });
};
export const saveSignData = (data) => {
  mainClient.writeFragment({
    id: `ITEM_DATA:${data.code}`,
    fragment: SIGN_DATA,
    data: { real_sign: data },
    overwrite: true,
  });
};

export const saveHogaData = (data) => {
  mainClient.writeFragment({
    id: `ITEM_DATA:${data.code}`,
    fragment: HOGA_DATA,
    data: { real_hoga: data },
    overwrite: true,
  });
};

export const getItemInfo = (code) => {
  return mainClient.readFragment({
    id: `ITEM_DATA:${code}`,
    fragment: ITEM_DATA,
  });
};

export const saveForeignItem = (items) => {
  // for (let i = 0; i < items.length; i++) {
  //   const { code } = items[i];
  //   const basicCode = code.slice(0, -3);
  //   mainClient.writeFragment({
  //     fragment: FOREIGN_DATA,
  //     id: `FOREIGN_DATA:${basicCode}`,
  //     data: { ...items[i], __typename: "ForeignItem" },
  //   });
  // }
  const codes = items.map((item) => {
    return { basicCode: item.code.slice(0, -3), hname: item.short_hname };
  });

  mainClient.writeFragment({
    fragment: FOREIGN_CODES,
    id: "FOREIGN_DATA:all",
    data: { codes, __typename: "ForeignCode" },
  });
};

export const getForeignItems = () => {
  const { codes } = mainClient.readFragment({
    fragment: FOREIGN_CODES,
    id: "FOREIGN_DATA:all",
  });

  // const data = [];
  // for (let i = 0; i < codes.length; i++) {
  //   data.push(
  //     mainClient.readFragment({
  //       fragment: FOREIGN_DATA,
  //       id: `FOREIGN_DATA:${codes[i]}`,
  //     })
  //   );
  // }

  return codes;
};
