import React from "react";

import { ApolloProvider } from "@apollo/client";
import Router from "./Router";

import { mainClient } from "./apollo";
import GlobalStyle from "./GlobalStyle";
import "antd/dist/reset.css";
function App() {
  return (
    <div className="App">
      <ApolloProvider client={mainClient}>
        <GlobalStyle />
        <Router />
      </ApolloProvider>
    </div>
  );
}

export default App;
