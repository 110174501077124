import React, { useState, useEffect } from "react";
import styled from "styled-components";

import {
  ProfitStatus,
  CurrentPositionStatus,
  MemberPosition,
  UnsignedPositon,
} from "./OrderStatusPresenter";
import Users from "../../RealTimeRequest/user";
import { mainClient } from "../../../../apollo";

import { SUBSCRIBE_REAL_SIGN } from "../../../graphql/itemInfo";
import { Row, Col } from "antd";
import _ from "lodash";
import { SUBSCRIBE_CURRENTSTATE } from "../../../graphql/manageUser";

const Container = styled.div`
  display: flex;
  .toolbox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  height: 100%;
`;

const getPositionState = () => {
  const users = new Users();
  const currentUsersData = users.initialize();
  const positionStatus = users.initializeOwnPosition();

  return {
    users: currentUsersData.users,
    status: positionStatus,
    userClass: users,
  };
};
const OrderStatus = (props) => {
  const [usersData, setUsersData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [positionCountData, setPositionCountData] = useState([]);
  const [signedPositionCountData, setSignedPositionCountData] = useState([]);
  const [unsignedPositionCountData, setUnsignedPositionCountData] = useState(
    []
  );

  useEffect(() => {
    const { users, status } = getPositionState();

    setUsersData(users);
    setPositionCountData(status);
    const subscribeCurrentState = mainClient
      .subscribe({
        query: SUBSCRIBE_CURRENTSTATE,
        fetchPolicy: "no-cache",
      })
      .subscribe(
        ({ data: { subscribeCurrentState: subscribeCurrentStateData } }) => {
          const { users, status, userClass } = getPositionState();

          const positions = userClass.getPositionData(selectedRowKeys[0]);
          setUsersData(users);
          setPositionCountData(status);
          setSignedPositionCountData(positions.signedPosition);
          setUnsignedPositionCountData(positions.unsignedPosition);
        }
      );

    return () => {
      subscribeCurrentState.unsubscribe();
    };
  }, [selectedRowKeys]);

  useEffect(() => {
    const subscribeSign = mainClient
      .subscribe({
        query: SUBSCRIBE_REAL_SIGN,
        fetchPolicy: "no-cache",
      })
      .subscribe(async ({ data: { subscribeRealSign: sign } }) => {
        const currentUsers = new Users();
        const { users } = currentUsers.initialize();
        if (_.isEqual(usersData, users)) return;
        setUsersData(users);
      });

    return () => {
      subscribeSign.unsubscribe();
    };
  }, [usersData]);
  const handleSelectionChanged = (rowkeys, selectedRows) => {
    // 회원별 체결포지션, 미체결포지션 보여주기
    const users = new Users();
    users.initialize();

    // const currentUsersId = rowkeys[0];
    const selectedUsers = rowkeys;
    const positions = users.getPoisitionDataWithSelected(selectedUsers);
    setSignedPositionCountData(positions.signedPosition);
    setUnsignedPositionCountData(positions.unsignedPosition);

    setSelectedRowKeys(rowkeys);
  };
  return (
    <Container>
      <Row wrap={false} gutter={[16, 16]}>
        <Col flex="800px">
          <ProfitStatus
            data={usersData}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            handleSelectionChanged={handleSelectionChanged}
          />
        </Col>
        <Col flex="auto">
          <Row style={{ height: "100%" }}>
            <Col>
              <CurrentPositionStatus data={positionCountData} />
            </Col>
            <Col>
              <MemberPosition data={signedPositionCountData} />
            </Col>
            <Col>
              <UnsignedPositon data={unsignedPositionCountData} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default React.memo(OrderStatus);
