import { gql } from "@apollo/client";

export const RealData = gql`
  fragment realData on RealData {
    code
    chetime
    price
    cgubun
    cvolume
    sign
    change
    drate
    open
    high
    low
    volume
    hotime
    offerho1
    bidho1
    offerrem1
    bidrem1
    offercnt1
    bidcnt1
    offerho2
    bidho2
    offerrem2
    bidrem2
    offercnt2
    bidcnt2
    offerho3
    bidho3
    offerrem3
    bidrem3
    offercnt3
    bidcnt3
    offerho4
    bidho4
    offerrem4
    bidrem4
    offercnt4
    bidcnt4
    offerho5
    bidho5
    offerrem5
    bidrem5
    offercnt5
    bidcnt5
    totofferrem
    totbidrem
    totoffercnt
    totbidcnt
  }
`;

export const SignData = gql`
  fragment signData on RealSign {
    code
    chetime
    price
    cgubun
    cvolume
    sign
    change
    drate
    open
    high
    low
    volume
  }
`;
export const HogaData = gql`
  fragment hogaData on RealHoga {
    code
    hotime
    offerho1
    bidho1
    offerrem1
    bidrem1
    offercnt1
    bidcnt1
    offerho2
    bidho2
    offerrem2
    bidrem2
    offercnt2
    bidcnt2
    offerho3
    bidho3
    offerrem3
    bidrem3
    offercnt3
    bidcnt3
    offerho4
    bidho4
    offerrem4
    bidrem4
    offercnt4
    bidcnt4
    offerho5
    bidho5
    offerrem5
    bidrem5
    offercnt5
    bidcnt5
    totofferrem
    totbidrem
    totoffercnt
    totbidcnt
  }
`;

export const Position = gql`
  fragment positions on Position {
    orderNo
    itemCode
    isBuy
    isSigned
    type
    count
    price
  }
`;
export const OrderLog = gql`
  fragment OrderLog on Log {
    orderNo
    originalOrderNo
    itemCode
    isBuy
    district
    type
    description
    isProgressing
    count
    price
    profitNLoss
    pureProfitNLoss
  }
`;

export const TotalLog = gql`
  fragment TotalLog on Log {
    id
    performer
    signedPosition
    unsignedPosition
  }
`;

export const UserAccountData = gql`
  fragment userAccountData on User {
    userBalance
    userAccountNo
    userAccountBank
    userAccountOwner
  }
`;

export const TotalProfitNLossSumData = gql`
  fragment totalProfitNLossSum on TotalProfitNLossSum {
    dateId
    domesticProfitNLoss
    domesticFee
    optionProfitNLoss
    optionFee
    foreignProfitNLoss
    foreignFee
    totalFee
    pureProfitNLoss
  }
`;
export const BankingHistoryData = gql`
  fragment bankingHistoryData on BankingHistoryData {
    id
    status
    isDeposit
    request_balance
    before_balance
    after_balance
    accountBank
    accountNo
    accountOwner
    memo
    createdAt
    lastUpdated
    user {
      id
      userName
      recommendCode
      userBalance
      authority
    }
  }
`;

export const TradingItem = gql`
  fragment tradingItem on TradingItem {
    code
    type
    hname
    month
    short_hname
    exch_name
    unit_price
    digit
    lastday_closeprice
    open_margin
    maintain_margin
    losscut
    listed_high_price
    listed_low_price
    product_type
    overnight_margin
    tick_value
    currency_code
    expiry_date
    d_day
    signRate
    clearRate
    basic_fee
    is_overnight_permit
    order_condition_type
    order_condition_value
    overnight_first_margin
    overnight_second_margin
    overnight_third_margin
  }
`;

export const TradingTime = gql`
  fragment tradingTime on TradingTime {
    id
    name
    is_available1
    is_available2
    is_available3
    trade_start1
    trade_order_end1
    trade_clear_end1
    trade_start2
    trade_order_end2
    trade_clear_end2
    trade_start3
    trade_order_end3
    trade_clear_end3
    is_available_breaking
    breaking_start
    breaking_end
  }
`;

export const UserBalanceHistory = gql`
  fragment userBalanceHistories on UserBalanceHistory {
    dateId
    userId
    userBalance
    totalFee
    profitNLoss
    pureProfitNLoss
  }
`;

export const AbuserSettingResult = gql`
  fragment abuserSettingResults on AbuserSettingResult {
    id
    condition_type
    condition_value
    notice_count
  }
`;
