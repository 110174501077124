import { gql } from "@apollo/client";
import { Position, UserAccountData, UserBalanceHistory } from "./fragments";

export const REQEUST_WAITING_APPROVE_LIST = gql`
  query RequestWaitingApproveList {
    requestWaitingApproveList {
      createdAt
      id
      userName
      email
      userAccountBank
      userAccountNo
      userAccountOwner
      phoneNo
      recommendCode
      userState
      domestic_fee
      option_fee
      foreign_fee
      hangseng_fee
      mini_foreign_fee
      mini_hangseng_fee
    }
  }
`;

export const APPRROVE_REGISTER_USER = gql`
  mutation ApproveRegisterUser(
    $id: String
    $recommendCode: String
    $domestic_fee: Float
    $option_fee: Float
    $foreign_fee: Float
    $hangseng_fee: Float
    $mini_foreign_fee: Float
    $mini_hangseng_fee: Float
    $isApproved: Boolean
    $deposit_accountId: Int
  ) {
    approveRegisterUser(
      id: $id
      recommendCode: $recommendCode
      domestic_fee: $domestic_fee
      option_fee: $option_fee
      foreign_fee: $foreign_fee
      hangseng_fee: $hangseng_fee
      mini_foreign_fee: $mini_foreign_fee
      mini_hangseng_fee: $mini_hangseng_fee
      isApproved: $isApproved
      deposit_accountId: $deposit_accountId
    ) {
      id
      userState
    }
  }
`;
export const CREATE_USER = gql`
  mutation CreateUserByManager(
    $id: String!
    $userPw: String!
    $userName: String!
    $phoneNo: String
    $email: String
    $recommendCode: String
    $userAccountBank: Int
    $userAccountNo: String
    $userAccountOwner: String
    $authority: Int
  ) {
    createUserByManager(
      id: $id
      userPw: $userPw
      userName: $userName
      phoneNo: $phoneNo
      email: $email
      recommendCode: $recommendCode
      userAccountBank: $userAccountBank
      userAccountNo: $userAccountNo
      userAccountOwner: $userAccountOwner
      authority: $authority
    ) {
      code
      data {
        id
        userPw
      }
    }
  }
`;

export const MANGER_LOGIN = gql`
  mutation ManagerLogin($id: String!, $userPw: String!) {
    managerLogin(id: $id, userPw: $userPw)
  }
`;

export const GET_MEMBER_LIST = gql`
  query GetUserList {
    getUserList {
      id
      userName
    }
  }
`;

export const GET_All_USER_DATA = gql`
  ${UserBalanceHistory}
  query GetAllUserData($withTester: Boolean) {
    getAllUserData(withTester: $withTester) {
      id
      isNew
      userName
      userPw
      phoneNo
      email
      recommendCode
      connectState
      createdAt
      lastLogin
      lastTrade
      connectIP
      connectMAC
      authority
      userState
      userBalance
      userAccountBank
      userAccountNo
      userAccountOwner
      connect_serverId
      connect_server {
        id
        name
        server
      }
      isOverDomestic
      updatedData_overDomestic
      isOverOption
      updatedData_overOption
      isOverForeign
      updatedData_overForeign
      isOverHangSeng
      updatedData_overHangseng
      isOverMiniForeign
      updatedData_miniForeign
      domestic_fee
      option_fee
      foreign_fee
      hangseng_fee
      mini_foreign_fee
      mini_hangseng_fee
      domestic_maxAvailableCount
      option_maxAvailableCount
      foreign_maxAvailableCount
      hangseng_maxAvailableCount
      mini_foreign_maxAvailableCount
      mini_hangseng_maxAvailableCount
      position {
        orderNo
        userId
        itemCode
        isBuy
        isSigned
        type
        count
        price
      }
      user_balance_history {
        ...userBalanceHistories
      }
      deposit_account {
        id
        bank
        accountName
        accountNo
        accountOwner
      }

      memo
    }
  }
`;

export const GET_ALL_USER_STATEDATA = gql`
  ${UserBalanceHistory}
  query GetUserDataToday($withTester: Boolean) {
    getUserDataToday(withTester: $withTester) {
      id
      isNew
      userName
      recommendCode
      connectState
      authority
      userBalance
      position {
        orderNo
        userId
        itemCode
        isBuy
        isSigned
        type
        count
        price
      }
      user_balance_history {
        ...userBalanceHistories
      }
    }
  }
`;

export const SUBSCRIBE_CURRENTSTATE = gql`
  ${Position}
  ${UserBalanceHistory}
  subscription SubscribeCurrentState($userId: String) {
    subscribeCurrentState(userId: $userId) {
      id
      authority
      userBalance
      position {
        ...positions
      }
      user_balance_history {
        ...userBalanceHistories
      }
    }
  }
`;

export const SUBSCRIBE_USER_CURRENTSTATE = gql`
  ${Position}
  subscription SubscribeUserCurrentState($userId: String) {
    subscribeUserCurrentState(userId: $userId) {
      id
      authority
      userBalance
      position {
        ...positions
      }
      user_balance_history {
        ...user_balance_history
      }
    }
  }
`;

export const GET_USER_DATA = gql`
  ${UserBalanceHistory}
  query GetUserData($id: String!) {
    getUserData(id: $id) {
      id
      isNew
      userName
      recommendCode
      connectState
      authority
      userBalance
      position {
        orderNo
        userId
        itemCode
        isBuy
        isSigned
        type
        count
        price
      }
      user_balance_history {
        ...userBalanceHistories
      }
    }
  }
`;

export const REVISE_USER_SETTING = gql`
  ${UserBalanceHistory}
  mutation ReviseUserSetting(
    $managerId: String!
    $userId: String!
    $userPw: String
    $userName: String
    $phoneNo: String
    $email: String
    $userBalance: Int
    $userState: Int
    $authority: Int
    $userAccountBank: Int
    $userAccountNo: String
    $userAccountOwner: String
    $deposit_accountId: Int
    $domestic_fee: Float
    $option_fee: Float
    $foreign_fee: Float
    $hangseng_fee: Float
    $mini_foreign_fee: Float
    $domestic_maxAvailableCount: Int
    $option_maxAvailableCount: Int
    $foreign_maxAvailableCount: Int
    $hangseng_maxAvailableCount: Int
    $mini_foreign_maxAvailableCount: Int
    $recommendCode: String
    $isOverDomestic: Boolean
    $isOverOption: Boolean
    $isOverForeign: Boolean
    $isOverHangSeng: Boolean
    $isOverMiniForeign: Boolean
    $memo: String
  ) {
    reviseUserSetting(
      managerId: $managerId
      userId: $userId
      userPw: $userPw
      userName: $userName
      phoneNo: $phoneNo
      email: $email
      userBalance: $userBalance
      userState: $userState
      authority: $authority
      userAccountBank: $userAccountBank
      userAccountNo: $userAccountNo
      userAccountOwner: $userAccountOwner
      deposit_accountId: $deposit_accountId
      domestic_fee: $domestic_fee
      option_fee: $option_fee
      foreign_fee: $foreign_fee
      hangseng_fee: $hangseng_fee
      mini_foreign_fee: $mini_foreign_fee
      domestic_maxAvailableCount: $domestic_maxAvailableCount
      option_maxAvailableCount: $option_maxAvailableCount
      foreign_maxAvailableCount: $foreign_maxAvailableCount
      hangseng_maxAvailableCount: $hangseng_maxAvailableCount
      mini_foreign_maxAvailableCount: $mini_foreign_maxAvailableCount
      isOverDomestic: $isOverDomestic
      isOverOption: $isOverOption
      isOverForeign: $isOverForeign
      isOverHangSeng: $isOverHangSeng
      isOverMiniForeign: $isOverMiniForeign
      recommendCode: $recommendCode
      memo: $memo
    ) {
      id
      isNew
      userName
      userPw
      phoneNo
      email
      recommendCode
      connectState
      createdAt
      lastLogin
      lastTrade
      connectIP
      authority
      userState
      userBalance
      userAccountBank
      userAccountNo
      userAccountOwner
      connect_server {
        id
        name
      }
      isOverDomestic
      isOverOption
      isOverForeign
      isOverHangSeng
      domestic_fee
      option_fee
      foreign_fee
      hangseng_fee
      mini_foreign_fee
      mini_hangseng_fee
      domestic_maxAvailableCount
      option_maxAvailableCount
      foreign_maxAvailableCount
      hangseng_maxAvailableCount
      mini_foreign_maxAvailableCount
      mini_hangseng_maxAvailableCount
      memo
      position {
        orderNo
        userId
        itemCode
        isBuy
        isSigned
        type
        count
        price
      }
      user_balance_history {
        ...userBalanceHistories
      }
      deposit_account {
        id
        bank
        accountName
        accountNo
        accountOwner
      }
    }
  }
`;

export const REVISE_RECOMMEND_RETURNDATA = gql`
  mutation ReviseRecommendReturnData(
    $userId: String!
    $returnType: Int
    $returnValue: Int
  ) {
    reviseRecommendReturnData(
      userId: $userId
      returnType: $returnType
      returnValue: $returnValue
    ) {
      id
      returnType
      returnValue
    }
  }
`;

export const GET_TEST_MEMBERS = gql`
  query GetTestMembers {
    getTestMembers {
      id
      lastLogin
      lastTrade
      userBalance
    }
  }
`;

export const GET_RECOMMENDER_INFO = gql`
  query GetRecommenderInfo {
    getRecommenderInfo {
      id
      recommendCode
      recommendName
      userCount
      returnType
      returnValue
      domestic_fee
      option_fee
      foreign_fee
      hangseng_fee
      mini_foreign_fee
      domestic_maxAvailableCount
      option_maxAvailableCount
      foreign_maxAvailableCount
      hangseng_maxAvailableCount
      mini_foreign_maxAvailableCount
    }
  }
`;

export const REVISE_RECOMMENDER_INFO = gql`
  mutation ReviseRecommenderInfo(
    $managerId: String!
    $id: Int!
    $recommendCode: String
    $returnType: Int
    $returnValue: Int
    $domestic_fee: Float
    $option_fee: Float
    $foreign_fee: Float
    $hangseng_fee: Float
    $mini_foreign_fee: Float
    $domestic_maxAvailableCount: Int
    $option_maxAvailableCount: Int
    $foreign_maxAvailableCount: Int
    $hangseng_maxAvailableCount: Int
    $mini_foreign_maxAvailableCount: Int
  ) {
    reviseRecommenderInfo(
      managerId: $managerId
      id: $id
      recommendCode: $recommendCode
      returnType: $returnType
      returnValue: $returnValue
      domestic_fee: $domestic_fee
      option_fee: $option_fee
      foreign_fee: $foreign_fee
      hangseng_fee: $hangseng_fee
      mini_foreign_fee: $mini_foreign_fee
      domestic_maxAvailableCount: $domestic_maxAvailableCount
      option_maxAvailableCount: $option_maxAvailableCount
      foreign_maxAvailableCount: $foreign_maxAvailableCount
      hangseng_maxAvailableCount: $hangseng_maxAvailableCount
      mini_foreign_maxAvailableCount: $mini_foreign_maxAvailableCount
    ) {
      code
      data {
        id
        recommendCode
        returnType
        returnValue
        domestic_fee
        option_fee
        foreign_fee
        hangseng_fee
        mini_foreign_fee
        domestic_maxAvailableCount
        option_maxAvailableCount
        foreign_maxAvailableCount
        hangseng_maxAvailableCount
        mini_foreign_maxAvailableCount
      }
    }
  }
`;

export const ADD_RECOMMENDER = gql`
  mutation AddRecommender(
    $id: String!
    $userName: String!
    $managerId: String!
    $returnType: Int
    $returnValue: Int
    $domestic_fee: Float
    $option_fee: Float
    $foreign_fee: Float
    $hangseng_fee: Float
    $mini_foreign_fee: Float
    $domestic_maxAvailableCount: Int
    $option_maxAvailableCount: Int
    $foreign_maxAvailableCount: Int
    $hangseng_maxAvailableCount: Int
    $mini_foreign_maxAvailableCount: Int
  ) {
    addRecommender(
      id: $id
      userName: $userName
      managerId: $managerId
      returnType: $returnType
      returnValue: $returnValue
      domestic_fee: $domestic_fee
      option_fee: $option_fee
      foreign_fee: $foreign_fee
      hangseng_fee: $hangseng_fee
      mini_foreign_fee: $mini_foreign_fee
      domestic_maxAvailableCount: $domestic_maxAvailableCount
      option_maxAvailableCount: $option_maxAvailableCount
      foreign_maxAvailableCount: $foreign_maxAvailableCount
      hangseng_maxAvailableCount: $hangseng_maxAvailableCount
      mini_foreign_maxAvailableCount: $mini_foreign_maxAvailableCount
    ) {
      code
    }
  }
`;

export const GET_SERVER_LIST = gql`
  query GetServerList {
    getServerList {
      data {
        id
        name
        server
      }
      code
    }
  }
`;

export const REVISE_USERBALANCE = gql`
  mutation ReviseUserBalance(
    $managerId: String!
    $userId: String!
    $isDeposit: Boolean!
    $value: Int!
  ) {
    reviseUserBalance(
      managerId: $managerId
      userId: $userId
      isDeposit: $isDeposit
      value: $value
    )
  }
`;

export const SUBSCRIBE_USER_SETTING = gql`
  ${UserAccountData}
  ${Position}
  subscription SubscribeUserSetting($userId: String) {
    subscribeUserSetting(userId: $userId) {
      id
      ...userAccountData
      userName
      phoneNo
      email
      recommendCode
      connectState
      authority
      userState
      isOverDomestic
      isOverOption
      isOverForeign
      isOverHangSeng
      domestic_fee
      option_fee
      foreign_fee
      hangseng_fee
      mini_foreign_fee
      domestic_maxAvailableCount
      option_maxAvailableCount
      foreign_maxAvailableCount
      hangseng_maxAvailableCount
      mini_foreign_maxAvailableCount
      mini_hangseng_maxAvailableCount
      user_balance_history {
        userBalance
        totalFee
        pureProfitNLoss
        dateId
      }
      position {
        ...positions
      }
    }
  }
`;
