import React, { createContext, useContext, useReducer } from "react";

const initialState = {
  registerUserData: [],
  requestBankingData: [],
};

// context
const RealTimeStateContext = createContext(null);

const RealTimeDispatchContext = createContext(null);

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE_REALTIME_REQUEST":
      return action.data;

    case "REQUEST_BANKING":
      return {
        ...state,
        requestBankingData: action.data,
      };
    case "REQUEST_REGISTER":
      return {
        ...state,
        registerUserData: action.data,
      };
    default:
      return state;
  }
};

export const RealTimeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <RealTimeStateContext.Provider value={state}>
      <RealTimeDispatchContext.Provider value={dispatch}>
        {children}
      </RealTimeDispatchContext.Provider>
    </RealTimeStateContext.Provider>
  );
};

export const useRealTimeState = () => {
  const state = useContext(RealTimeStateContext);

  if (!state) throw new Error("Cannot find RealTimeProvider");
  return state;
};

export const useRealTimeDispatch = () => {
  const dispatch = useContext(RealTimeDispatchContext);

  if (!dispatch) throw new Error("Cannot find RealTimeProvider");
  return dispatch;
};
