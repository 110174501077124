import { gql } from "@apollo/client";
import { TradingTime, TradingItem, SignData, HogaData } from "./fragments";
export const GET_ALL_ITEMINFO = gql`
  ${TradingItem}
  ${TradingTime}
  ${SignData}
  ${HogaData}
  query GetAllItemInfo {
    getAllItemInfo {
      ...tradingItem
      currency_data {
        id
        value
      }
      real_hoga {
        ...hogaData
      }
      real_sign {
        ...signData
      }
      trading_time {
        ...tradingTime
      }
      currency_data {
        id
        value
      }
    }
  }
`;

export const GET_OPTION_INFO = gql`
  ${SignData}
  ${HogaData}
  ${TradingTime}
  ${TradingItem}
  query GetOptionInfo {
    getOptionInfo {
      callOption {
        ...tradingItem
        currency_data {
          id
          value
        }
        real_hoga {
          ...hogaData
        }
        real_sign {
          ...signData
        }
        trading_time {
          ...tradingTime
        }
      }
      putOption {
        ...tradingItem
        currency_data {
          id
          value
        }
        real_hoga {
          ...hogaData
        }
        real_sign {
          ...signData
        }
        trading_time {
          ...tradingTime
        }
      }
    }
  }
`;

// Manage Option Trade Condition
export const GET_MANAGE_OPTION_TRADE_CONDITION = gql`
  query GetOptionTradeCondition {
    getOptionTradeCondition {
      make_trade_possible_lowlimit
      make_trade_impossible_lowlimit
      make_trade_possible_highlimit
      make_trade_impossible_highlimit
    }
  }
`;

// Revise Option Trade Condition
export const REVISE_OPTION_TRADE_CONDITION = gql`
  mutation ReviseOptionTradeCondition(
    $make_trade_possible_lowlimit: Float
    $make_trade_impossible_lowlimit: Float
    $make_trade_possible_highlimit: Float
    $make_trade_impossible_highlimit: Float
  ) {
    reviseOptionTradeCondition(
      make_trade_possible_lowlimit: $make_trade_possible_lowlimit
      make_trade_impossible_lowlimit: $make_trade_impossible_lowlimit
      make_trade_possible_highlimit: $make_trade_possible_highlimit
      make_trade_impossible_highlimit: $make_trade_impossible_highlimit
    ) {
      code
      data {
        make_trade_possible_lowlimit
        make_trade_impossible_lowlimit
        make_trade_possible_highlimit
        make_trade_impossible_highlimit
      }
    }
  }
`;

// Hoga
export const SUBSCRIBE_REAL_HOGA = gql`
  ${HogaData}
  subscription {
    subscribeReal {
      ...hogaData
    }
  }
`;

// Sign
export const SUBSCRIBE_REAL_SIGN = gql`
  ${SignData}
  subscription {
    subscribeRealSign {
      ...signData
    }
  }
`;
