import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import styled from "styled-components";

const Container = styled.div``;

const CustomEditor = ({ ...props }) => {
  return (
    <Container>
      <ReactQuill theme="snow" {...props} style={{ height: "640px" }} />
    </Container>
  );
};

export default CustomEditor;
