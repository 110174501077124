export default function notification({ title, content, error }) {
  if ("Notification" in window) {
    Notification.requestPermission(function (result) {
      if (result === "denied")
        alert(
          "알람을 차단하셨습니다. \n 브라우저의 사이트 설정에서 변경하실 수 있습니다"
        );

      if (result === "granted") {
        const noti = new Notification(title, { body: content });
      }
    });
  } else {
    console.log("This browser does not support notifications");
  }
}
