import { gql } from "@apollo/client";

export const WRITE_MEMBER_ID = gql`
  fragment userData on UserList {
    users
  }
`;

export const USER_DATA = gql`
  fragment user on UserData {
    id
    isNew
    userName
    userPw
    phoneNo
    email
    recommendCode
    connectState
    createdAt
    lastLogin
    lastTrade
    connectIP
    connectMAC
    authority
    userState
    userBalance
    userAccountBank
    userAccountNo
    userAccountOwner
    connect_serverId
    connect_server {
      id
      name
      server
    }
    isOverDomestic
    updatedData_overDomestic
    isOverOption
    updatedData_overOption
    isOverForeign
    updatedData_overForeign
    isOverHangSeng
    updatedData_overHangseng
    isOverMiniForeign
    updatedData_miniForeign
    domestic_fee
    option_fee
    foreign_fee
    hangseng_fee
    mini_foreign_fee
    mini_hangseng_fee
    domestic_maxAvailableCount
    option_maxAvailableCount
    foreign_maxAvailableCount
    hangseng_maxAvailableCount
    mini_foreign_maxAvailableCount
    mini_hangseng_maxAvailableCount
    position
    user_balance_history
    deposit_account {
      id
      bank
      accountName
      accountNo
      accountOwner
    }
    memo
  }
`;

export const CURRENT_STATE = gql`
  fragment currentState on UserData {
    position
    user_balance_history
    isOverHangSeng
    isOverOption
    isOverForeign
    isOverDomestic
  }
`;
