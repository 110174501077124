import React, { useState } from "react";
import ContextMenu from "../../../common/ContextMenu";
import {
  DateCell,
  MemberGradeCellRender,
  ValueCell,
} from "../../../common/CustomCell";
import { getBankName } from "../../../common/data";
import { CustomBasicTable } from "../../../common/CustomTable";
import { Card, Button } from "antd";

export const memberListColumns = (handleOnClick) => [
  {
    title: "아이디",
    dataIndex: "id",
    key: "id",
    fixed: "left",
    width: 120,
    sorter: (a, b) => a.id > b.id,
    className: "padding-0",
  },
  {
    title: "기본정보",
    children: [
      {
        title: "가입일시",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 160,
        search: false,
        render: (value) => <DateCell value={value} type={0} />,
        sorter: (a, b) => a.createdAt > b.createdAt,
        className: "padding-0",
      },
      {
        title: "이름",
        dataIndex: "userName",
        key: "userName",
        width: 120,
        className: "padding-0",
      },
      {
        title: "패스워드",
        dataIndex: "userPw",
        key: "userPw",
        width: 80,
        search: false,
        className: "padding-0",
      },
      {
        title: "휴대폰",
        dataIndex: "phoneNo",
        key: "phoneNo",
        width: 120,
        className: "padding-0",
      },
      {
        title: "이메일",
        dataIndex: "email",
        key: "email",
        width: 160,
        className: "padding-0",
      },
      {
        title: "추천인",
        dataIndex: "recommendCode",
        key: "recommendCode",
        width: 96,
        search: false,
        className: "padding-0",
      },
      {
        title: "접속상태",
        dataIndex: "connectState",
        key: "connectState",
        width: 80,
        className: "padding-0",
        render: (value) => <div>{value && "접속중"}</div>,
      },
      {
        title: "최종접속일시",
        dataIndex: "lastLogin",
        key: "lastLogin",
        width: 160,
        render: (value) => <DateCell value={value} type={0} />,
        className: "padding-0",
      },
      {
        title: "마지막거래일시",
        dataIndex: "lastTrade",
        key: "lastLogin",
        width: 160,
        render: (value) => <DateCell value={value} type={0} />,
        className: "padding-0",
      },
      {
        title: "접속IP",
        dataIndex: "connectIP",
        key: "connectIp",
        width: 160,
        className: "padding-0",
      },
      {
        title: "접속MAC",
        dataIndex: "connectMAC",
        key: "connectMac",
        width: 160,
        className: "padding-0",
      },
      {
        title: "권한",
        dataIndex: "authority",
        key: "authority",
        width: 120,
        className: "padding-0",
        render: (value) => <MemberGradeCellRender value={value} />,
      },
      {
        title: "계정상태",
        dataIndex: "userState",
        key: "userState",
        width: 96,
        className: "padding-0",
        render: (value) => <span>{value === 1 ? "정상" : "블랙"}</span>,
        className: "padding-0",
      },
      {
        title: "담보금",
        dataIndex: "userBalance",
        key: "userBalance",
        width: 120,
        className: "padding-0",
        sorter: (a, b) => a.userBalance > b.userBalance,
        render: (value) => <ValueCell value={value} type={0} />,
        className: "padding-0",
      },
      {
        title: "입금은행",
        children: [
          {
            title: "입금은행 아이디",
            dataIndex: "deposit_accountId",
            key: "userBalance",
            width: 120,
            hideInTable: true,
            className: "padding-0",
          },
          {
            title: "입금은행",
            dataIndex: "deposit_accountName",
            key: "deposit_accountName",
            width: 120,
            className: "padding-0",
          },
          {
            title: "입금은행명",
            dataIndex: "deposit_accountBank",
            key: "deposit_accountBank",
            width: 120,
            render: (value) => <span>{getBankName(value)}</span>,
            className: "padding-0",
          },
          {
            title: "입금은행 계좌주",
            dataIndex: "deposit_accountOwner",
            key: "deposit_accountOwner",
            width: 160,
            className: "padding-0",
          },
          {
            title: "입금은행 계좌번호",
            dataIndex: "deposit_accountNo",
            key: "deposit_accountNo",
            width: 160,
            className: "padding-0",
          },
        ],
      },

      {
        title: "출금은행",
        dataIndex: "userAccountBank",
        key: "userAccountBank",
        width: 120,
        render: (value) => <span>{getBankName(value)}</span>,
        className: "padding-0",
      },
      {
        title: "출금계좌",
        dataIndex: "userAccountNo",
        key: "userAccountNo",
        width: 160,
        className: "padding-0",
      },
      {
        title: "출금예금주",
        dataIndex: "userAccountOwner",
        key: "userAccountOwner",
        width: 120,
        className: "padding-0",
      },
    ],
  },
  {
    title: "오버나잇",
    children: [
      {
        title: "국선오버나잇",
        dataIndex: "isOverDomestic",
        key: "isOverDomestic",
        width: 120,
        className: "padding-0",
      },
      {
        title: "국선오버나잇 일시",
        dataIndex: "updatedData_overDomestic",
        key: "updatedData_overDomestic",
        width: 160,
        render: (value) => <DateCell value={value} type={0} />,
        className: "padding-0",
      },
      {
        title: "옵션오버나잇",
        dataIndex: "isOverOption",
        key: "isOverOption",
        width: 120,
        className: "padding-0",
      },
      {
        title: "옵션오버나잇 일시",
        dataIndex: "updatedData_overOption",
        key: "updatedData_overOption",
        width: 160,
        render: (value) => <DateCell value={value} type={0} />,
        className: "padding-0",
      },
      {
        title: "해선오버나잇",
        dataIndex: "isOverForeign",
        key: "isOverForeign",
        width: 120,
        className: "padding-0",
      },
      {
        title: "해선오버나잇 일시",
        dataIndex: "updatedData_overForeign",
        key: "updatedData_overForeign",
        width: 160,
        render: (value) => <DateCell value={value} type={0} />,
        className: "padding-0",
      },
      {
        title: "항셍오버나잇",
        dataIndex: "isOverHangSeng",
        key: "isOverHangSeng",
        width: 120,
        className: "padding-0",
      },
      {
        title: "항셍오버나잇 일시",
        dataIndex: "updatedData_overHangseng",
        key: "updatedData_overHangseng",
        width: 160,
        render: (value) => <DateCell value={value} type={0} />,
        className: "padding-0",
      },
      {
        title: "마이크로",
        dataIndex: "isOverMiniForeign",
        key: "isOverMiniForeign",
        width: 120,
        className: "padding-0",
      },
      {
        title: "마이크로 일시",
        dataIndex: "updatedData_miniForeign",
        key: "updatedData_miniForeign",
        width: 160,
        render: (value) => <DateCell value={value} type={0} />,
        className: "padding-0",
      },
    ],
  },
  {
    title: "수수료",
    children: [
      {
        title: "국내선물",
        dataIndex: "domestic_fee",
        key: "domestic_fee",
        width: 96,
        className: "padding-0",
      },
      {
        title: "옵션",
        dataIndex: "option_fee",
        key: "option_fee",
        width: 96,
        className: "padding-0",
      },
      {
        title: "해외선물",
        dataIndex: "foreign_fee",
        key: "foreign_fee",
        width: 96,
        className: "padding-0",
      },
      {
        title: "항셍",
        dataIndex: "hangseng_fee",
        key: "hangseng_fee",
        width: 96,
        className: "padding-0",
      },
    ],
  },
  {
    title: "최대계약수",
    children: [
      {
        title: "국내선물",
        dataIndex: "domestic_maxAvailableCount",
        key: "domestic_maxAvailableCount",
        width: 96,
        className: "padding-0",
      },
      {
        title: "옵션",
        dataIndex: "option_maxAvailableCount",
        key: "option_maxAvailableCount",
        width: 96,
        className: "padding-0",
      },
      {
        title: "옵션매도",
        dataIndex: "optionSellCount",
        key: "optionSellCount",
        width: 96,
        className: "padding-0",
      },
      {
        title: "해외선물",
        dataIndex: "foreign_maxAvailableCount",
        key: "foreign_maxAvailableCount",
        width: 96,
        className: "padding-0",
      },
      {
        title: "항셍",
        dataIndex: "hangseng_maxAvailableCount",
        key: "hangseng_maxAvailableCount",
        width: 96,
        className: "padding-0",
      },
      {
        title: "마이크로해외선물",
        dataIndex: "mini_foreign_maxAvailableCount",
        key: "mini_foreign_maxAvailableCount",
        width: 136,
        className: "padding-0",
      },
    ],
  },
  {
    title: "메모",
    dataIndex: "memo",
    key: "memo",
    width: 160,
    className: "padding-0",
  },
  {
    title: "기능",
    valueType: "option",
    width: 120,
    fixed: "right",
    render: (_, record) => (
      <Button type="link" onClick={() => handleOnClick(record)}>
        수정
      </Button>
    ),
    className: "padding-0",
  },
];

export const MemberList = (props) => {
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [contextUser, setContextUser] = useState({});
  const { dataSource, pagination, handleOnClick } = props;
  const columns = memberListColumns(handleOnClick);

  const handleRightClick = (event, record) => {
    event.preventDefault();
    setContextUser(record.id);
    setPosition({ x: event.clientX, y: event.clientY });
    setOpen(true);
  };

  return (
    <Card>
      <CustomBasicTable
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 1600 }}
        rowKey="id"
        pagination={pagination}
        onRow={(record) => {
          return {
            onContextMenu: (event) => {
              handleRightClick(event, record);
            },
          };
        }}
      />

      <ContextMenu
        open={open}
        position={position}
        setOpen={setOpen}
        contextUser={contextUser}
      />
    </Card>
  );
};
